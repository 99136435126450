import axios from 'axios'
import { useMemo, useState } from 'react'
import config from '../config'

export function useICEServers() {
    const [iceServers, setIceServers] = useState<RTCIceServer[]>([])

    useMemo(() => {
        axios.get(config.webRTC.urls.ice).then(({ data: iceServers }) => {
            const filteredIce = iceServers.filter((ice: RTCIceServer) => {
                let tcp: boolean = false
                ;(ice.urls as string[]).filter((url) => {
                    tcp = url.indexOf('transport=tcp') >= 0
                })
                return tcp
            })
            setIceServers(filteredIce)
        })
    }, [])

    return iceServers
}
