import { CheckboxQuestion } from './Checkbox'
import { ConsentQuestion } from './Consent'
import { InterimSuccess } from './InterimSuccess'
import { NoRecPermissions } from './NoRecPermissions'
import { NoSound } from './NoSound'
import { RecPermissionQuestion } from './RecPermission'
import { SoundCheckQuestion } from './SoundCheck'
import { UnsupportedBrowser } from './UnsupportedBrowser'

export function CheckboxQuestionFactory(props: Novoic.CheckboxQuestionProps) {
    const { question } = props.question
    switch (question.category) {
        case 'checkbox':
            return <CheckboxQuestion {...props} />
        case 'no-sound':
            return <NoSound {...props} />
        case 'consent':
            return <ConsentQuestion {...props} />
        case 'no-rec-permissions':
            return <NoRecPermissions {...props} />
        case 'sound-check':
            return <SoundCheckQuestion {...props} />
        case 'rec-permission':
            return <RecPermissionQuestion {...props} />
        case 'interim-success':
            return <InterimSuccess {...props} />
        case 'unsupported-browser':
            return <UnsupportedBrowser />
    }
}
