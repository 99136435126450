import { PropsWithChildren } from 'react'
import ReactMarkdown from 'react-markdown'

import css from './Instructions.module.css'

interface InstructionProps extends PropsWithChildren {
    instructions: string
}

export function Instructions({ instructions }: InstructionProps) {
    return (
        <div className={css.container}>
            <div className={css.instructions}>
                <div>
                    <ReactMarkdown>{instructions}</ReactMarkdown>
                </div>
            </div>
        </div>
    )
}
