@import './Choice.module.css';

.radio_input {
    display: none;
}

.button:focus,
.button:hover {
    background-color: var(--indigo100);
}

.button {
    display: flex;
    justify-content: center;
    padding: 12px;
}

.selected {
    border: 1px solid var(--neutural900) !important;
    border-radius: var(--radius-md) !important;
    outline: none !important;
    box-shadow: 0px 0px 0px 6px var(--indigo300);
    z-index: 1;
}

.choice_container {
    gap: 16px;
}

.label_container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    color: var(--neutural500);
}

.high_label {
    text-align: end;
}

@media only screen and (min-width: 768px) {
    .high_label {
        text-align: left;
    }

    .button {
        padding: 16px 24px;
    }
}
