@keyframes overlay {
    0% {
        transform: scale(0.97);
    }
    100% {
        transform: scale(1.03);
    }
}

.container {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: center;
    align-items: center;
}

.button {
    width: 112px;
    height: 112px;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    position: relative;
}

.button:focus {
    outline: 0;
}

.button:disabled {
    cursor: default;
}

.button_text {
    padding: 12px;
    font-weight: 400;
    font-family: 'Inter', 'system-ui';
    font-size: 20px;
    line-height: 24px;
    transition: all 200ms ease-out;
}

.overlay {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;

    content: '';

    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: scale(1.03);

    border-radius: 50%;
    opacity: 0;
    transition: all 200ms ease-out;
}

@media only screen and (min-width: 768px) {
    .button:not([disabled]):hover .button_text,
    .button:focus .button_text {
        transform: translateY(16px);
        opacity: 0.1;
    }

    .button:not([disabled]):hover .overlay,
    .button:focus .overlay {
        opacity: 1;
        animation: 200ms overlay ease-in-out;
    }

    .button:not([disabled]):focus .player-overlay,
    .button:focus .player-overlay {
        background-color: var(--indigo800);
    }

    .button:not([disabled]):focus .recorder-overlay,
    .button:focus .recorder-overlay {
        background-color: var(--red800);
    }

    .button:not([disabled]):focus {
        transform: scale(1);
    }
}
