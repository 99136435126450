import axios from 'axios'
import i18n from 'i18next'
import { createRoot } from 'react-dom/client'
import { initReactI18next } from 'react-i18next'
import { App } from './components/App'

import enUS from './i18n/en-US'
import es from './i18n/es'

export function start(
    element: HTMLElement,
    { assignmentId, language, token, onComplete, onExit, onTimeout }: Novoic.Data
) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`

    i18n.use(initReactI18next).init({
        resources: {
            en: {
                translation: enUS,
            },
            es: {
                translation: es,
            },
        },
        lng: language,
        fallbackLng: 'en',
    })

    const root = createRoot(element)
    root.render(
        <App
            assignmentId={assignmentId}
            onComplete={onComplete}
            onTimeout={onTimeout}
            onExit={onExit}
        />
    )
}
