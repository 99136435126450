import { PropsWithChildren } from 'react'
import ReactMarkdown from 'react-markdown'
import config from '../../../config'
import { Icon } from '../../Icon'
import { RegularCaption } from '../../typography/Typography'

import css from './List.module.css'

interface ListProps extends PropsWithChildren {
    list: Novoic.ListItem[]
}

export function List({ list }: ListProps) {
    return (
        <div className={css.container}>
            <ul className={css.list}>
                {list.map((item: Novoic.ListItem) => {
                    return (
                        <li key={item.icon}>
                            <Icon name={item.icon} />
                            <div className={css.text}>
                                <ReactMarkdown components={{ p: RegularCaption }}>
                                    {item.text}
                                </ReactMarkdown>
                            </div>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}
