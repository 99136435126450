import { Instructions } from './sections/Instructions'

import css from './InterimSuccess.module.css'
import { Title } from './sections/Title'

const INSTRUCTIONS = `To continue, please do the following:\n - Tap the
three dots (**···**) at the top of the page.\n - Select **Open in ...** to open Storyteller in your usual internet browser, such as Chrome, Firefox, Edge or Safari.\n\n&nbsp;\n\nYou will be redirected to log into the study website to complete the rest of your experience.`

export function UnsupportedBrowser() {
    return (
        <div className={css.container}>
            <div className={css.title_container}>
                <Title>Please open Storyteller in a different internet browser</Title>
            </div>
            <Instructions instructions={INSTRUCTIONS} />
        </div>
    )
}
