.container {
    height: 100%;
    display: flex;
    gap: 16px;
    flex-direction: column;
    flex-grow: 1;
    position: relative;
    flex: 1;
}

.button_container {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    gap: 8px;
    margin: 8px 0 0;
    flex-direction: column-reverse;
    margin-top: auto;
}

.progress {
    flex-direction: row;
    justify-content: flex-end;
}

@media only screen and (min-width: 768px) {
    .container {
        min-height: 304px;
    }
}
