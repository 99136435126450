export default {
    alts: {
        closeModal: 'Cerrar modal',
    },
    buttons: {
        next: 'Continuar',
        tryAgain: 'Prueba de nuevo',
        finish: 'Terminar',
        submit: 'Enviar',
        confirm: 'Confirmar',
        agree: 'Estoy de acuerdo',
        agreeParticipate: 'Acepto participar',
        downloadPDF: 'Descargar como PDF',
        yes: 'Sí',
        no: 'No',
        consent: {
            confirmQuietPlace: 'Estoy en un lugar tranquilo',
            confirmYourBest: 'Lo haré lo mejor que pueda',
            confirmAid: 'Lo confirmo',
            confirmOnYourOwn: 'Haré las tareas por mi cuenta',
            confirmLater: 'Luego regreso',
        },
        exit: 'No, Salir',
    },
    errors: {
        wer: [
            {
                title: `Uy. No hemos entendido lo que has dicho.`,
                instructions: `- Recuerda que la grabación empieza automáticamente.\n - Asegúrate de repetir la frase y no decir nada más.\n - Presiona el botón de parada cuando hayas terminado.`,
                button: 'Prueba de nuevo',
            },
            {
                title: `Ummm. Seguimos sin entender lo que has dicho.`,
                instructions: `- Recuerda que la grabación empieza automáticamente.\n - Asegúrate de repetir la frase y no decir nada más.\n - Presiona el botón de parada cuando hayas terminado.`,
                button: 'Prueba una última vez',
            },
        ],
        noise: [
            {
                title: `Uy, parece que hay algo de ruido de fondo.`,
                instructions: `Encuentra un lugar más tranquilo o apaga cualquier cosa que esté haciendo ruido (televisión, música, etc.) y comprueba que nadie esté hablando de fondo.`,
                button: 'Prueba de nuevo',
            },
            {
                title: `Uy, parece que sigue habiendo algo de ruido de fondo.`,
                instructions: `Encuentra un lugar más tranquilo o apaga cualquier cosa que esté haciendo ruido (televisión, música, etc.) y comprueba que nadie esté hablando de fondo.`,
                button: 'Prueba una última vez',
            },
        ],
        clipping: [
            {
                title: `La grabación está demasiado alta.`,
                instructions: `- Puedes probar a alejar el micrófono un poco de ti.\n - Intenta hablar un poco más bajo.\n - Asegúrate de estar en un lugar tranquilo.`,
                button: 'Prueba de nuevo',
            },
            {
                title: `La grabación sigue estando demasiado alta.`,
                instructions: `- Puedes probar a alejar el micrófono un poco de ti.\n - Intenta hablar un poco más bajo.\n - Asegúrate de estar en un lugar tranquilo.`,
                button: 'Prueba una última vez',
            },
        ],
    },
    modals: {
        exit: {
            caption: '¿Salir de Storyteller?',
            text: 'Podrás regresar cuando estés preparado.',
        },
        expired: {
            caption: 'La sesión ha caducado',
            text: 'Llevas un tiempo inactivo, por lo que tu sesión ha finalizado. Presiona Reiniciar para volver al principio de Storyteller.',
            buttons: {
                confirm: 'Reanudar',
                cancel: 'Salir',
            },
        },
        genericError: {
            caption: 'Algo salió mal',
            text: 'Pedimos disculpas por las molestias. Parece que algo no funciona correctamente de nuestra parte. Haga clic en Intentar de nuevo para continuar. Si el problema persiste, póngase en contacto con el soporte.',
            buttons: {
                confirm: 'Intentar otra vez',
                cancel: 'Salir',
            },
        },
        soundCheck: {
            caption: '¿Has escuchado el sonido?',
        },
        completeStreaming: {
            caption: '¿Has terminado?',
        },
    },
    consent: {
        title: 'El consentimiento',
        description: 'Lea atentamente el formulario de consentimiento e indique su acuerdo.',
    },
    recording: 'Grabación en marcha',
    streaming: {
        play: 'Empezar',
        playing: 'Escuchar',
        record: 'Empezar',
        recordingAutomatic: 'Hablar',
        recordingManual: 'Parar',
    },
    soundCheck: {
        audioStart: 'Revisa el sonido',
        audioStop: 'Escuchar',
    },
}
