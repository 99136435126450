import { useState, useEffect } from 'react'
import config from '../config'

export function useFonts(): boolean {
    const [areFontsLoaded, setAreFontsLoaded] = useState<boolean>(false)

    useEffect(() => {
        const fonts: FontFace[] = [
            new FontFace(
                'Gelica W01 SemiBold',
                `url(${config.s3.getStaticURL('08b919ee-494b-4a67-a796-d7932290f8de.woff2')})`
            ),
            new FontFace(
                'Inter',
                `url(${config.s3.getStaticURL('Inter+Web/Inter.var.woff2')})`,
                {}
            ),
            new FontFace('Inter', `url(${config.s3.getStaticURL('Inter+Web/Inter-Light.woff2')})`, {
                weight: '300',
            }),
            new FontFace(
                'Inter',
                `url(${config.s3.getStaticURL('Inter+Web/Inter-Regular.woff2')})`,
                {
                    weight: '400',
                }
            ),
            new FontFace(
                'Inter',
                `url(${config.s3.getStaticURL('Inter+Web/Inter-Medium.woff2')})`,
                {
                    weight: '500',
                }
            ),
            new FontFace(
                'Inter',
                `url(${config.s3.getStaticURL('Inter+Web/Inter-SemiBold.woff2')})`,
                {
                    weight: '600',
                }
            ),
        ]

        Promise.all(fonts.map((font) => font.load()))
            .then((fonts) => {
                fonts.forEach((font) => document.fonts.add(font))
            })
            .finally(() => {
                setAreFontsLoaded(true)
            })
    }, [])

    return areFontsLoaded
}
