import * as Sentry from '@sentry/browser'

/**
 * Connection isn't yet part of the standard, but it is supported by Chrome and Firefox.
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Navigator/connection
 */
interface Connection {
    connection: {
        downlink: number
        rtt: number
        type: string
    }
}

export function useSentry(): void {
    Sentry.init({
        dsn: 'https://b5a60d6a153e4065b4c271cf4b6675d5@o369932.ingest.sentry.io/4504084002963456',
        integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
        environment: process.env.NODE_ENV,
        tracesSampleRate: 1.0,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    })

    if ((navigator as Navigator & Connection).connection) {
        Sentry.setContext('Connection', {
            downlink: (navigator as Navigator & Connection).connection.downlink,
            rtt: (navigator as Navigator & Connection).connection.rtt,
            type: (navigator as Navigator & Connection).connection.type,
        })
    }
}
