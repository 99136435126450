import { useState } from 'react'
import { NavigationButton } from '../NavigationButton'

import css from './Choice.module.css'
import { RegularCaption } from '../typography/Typography'
import { Instructions } from './sections/Instructions'
import { Title } from './sections/Title'
import { MobileProgress, DesktopProgress } from '../Progress/Progress'

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

export function ChoiceQuestion({ onSubmit, question }: Novoic.ChoiceQuestionProps) {
    const { id, question: body } = question
    const { title, accent, instructions, subtext, choices, button } = body

    const [errors, setErrors] = useState<Record<string, any>>({})
    const [state, setState] = useState(
        choices.map((choice) => ({
            ...choice,
            value: choice.default,
            pristine: true,
        }))
    )

    async function onSubmitForm() {
        const errors = await onSubmit(id, {
            choices: state.reduce((acc, radio) => {
                acc[radio.name] = radio.value
                return acc
            }, {} as Record<string, string | number>),
        })

        console.log(errors)

        if (errors && Object.keys(errors).length) {
            setErrors(errors)
        }
    }

    function onChange(name: string, value: string | number) {
        const newState = state.map((radio) => {
            if (radio.name === name) {
                radio.value = value
                radio.pristine = false
            }

            return radio
        })

        delete errors[name]
        setErrors(errors)
        setState(newState)
    }

    return (
        <div className={css.container}>
            <MobileProgress />
            <Title accent={accent}>{title}</Title>
            {subtext && <RegularCaption>{subtext}</RegularCaption>}
            {instructions && <Instructions instructions={instructions} />}

            <div className={css.choice_container}>
                {state.map((radio) => (
                    <div key={radio.name} className={css.item}>
                        <RegularCaption>{radio.title}</RegularCaption>
                        <div className={css.horizontal}>
                            <div
                                className={classNames(
                                    css.radio_container,
                                    errors[radio.name] ? css.error : css.normal
                                )}
                            >
                                {radio.options.map((option) => (
                                    <button
                                        className={css.button}
                                        key={option.title}
                                        onClick={() => onChange(radio.name, option.value)}
                                    >
                                        <input
                                            id={radio.name + option.title}
                                            className={css.radio_input}
                                            type="radio"
                                            value={option.value}
                                            checked={radio.value === option.value}
                                            name={radio.name}
                                            onChange={() => onChange(radio.name, option.value)}
                                        />
                                        <label htmlFor={radio.name + option.title}>
                                            <RegularCaption>{option.title}</RegularCaption>
                                        </label>
                                    </button>
                                ))}
                            </div>
                            <div className={css.spacer}></div>
                        </div>
                    </div>
                ))}
            </div>

            <div className={css.button_container}>
                <DesktopProgress />
                {Object.keys(errors).length > 0 ? (
                    <div className={css.error_message_container}>
                        <span className={css.error_message}>
                            Please answer all the questions above to finish the test
                        </span>
                    </div>
                ) : (
                    <NavigationButton role="confirm" label={button} onClick={onSubmitForm} />
                )}
            </div>
        </div>
    )
}
