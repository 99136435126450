@keyframes spinner {
    0% {
        transform: rotate(0turn);
    }

    100% {
        transform: rotate(1turn);
    }
}

.container {
    composes: container from './Button.module.css';
}

.button {
    composes: button from './Button.module.css';
}

.button_text {
    composes: button_text from './Button.module.css';
}

.overlay {
    composes: overlay from './Button.module.css';
}

.processing {
    background-color: var(--indigo50);
}

.processing_outside {
    border: 2px solid transparent;
    padding: 6px;
    border-radius: 50%;
}

.loader {
    display: inline;
    animation-name: spinner;
    animation-duration: 500ms;
    animation-timing-function: ease-out;
    animation-iteration-count: infinite;
}
