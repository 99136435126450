export default {
    alts: {
        closeModal: 'Close modal',
    },
    buttons: {
        next: 'Continue',
        tryAgain: 'Try Again',
        finish: 'Finish',
        submit: 'Submit',
        confirm: 'Confirm',
        agree: 'I Agree',
        agreeParticipate: 'I Agree To Participate',
        downloadPDF: 'Download as PDF',
        yes: 'Yes',
        no: 'No',
        consent: {
            confirmQuietPlace: 'I’m in a Quiet Place',
            confirmYourBest: 'I’ll Do My Best!',
            confirmAid: 'I Confirm',
            confirmOnYourOwn: 'I’ll Do Tasks On My Own',
            confirmLater: 'I’ll Come Back Later',
        },
        exit: 'No, Exit',
    },
    errors: {
        wer: [
            {
                title: `Uh-oh. We didn’t catch what you said there.`,
                instructions: `- Remember the recording begins automatically.\n - Make sure you repeat the sentence and nothing else.\n - Press the stop button when you have finished.`,
                button: 'Try Again',
            },
            {
                title: `Hmm. We still didn’t catch what you said there. `,
                instructions: `- Remember the recording begins automatically.\n - Make sure you repeat the sentence and nothing else.\n - Press the stop button when you have finished.`,
                button: 'Try One Last Time',
            },
        ],
        noise: [
            {
                title: `Uh-oh, it sounds like there is some background noise.`,
                instructions: `Please find a quieter place or switch off anything that is making noise (TV, music, etc.), and make sure no one is talking in the background.`,
                button: 'Try Again',
            },
            {
                title: `Hmm, it sounds like there is still some background noise.`,
                instructions: `Please find a quieter place or switch off anything that is making noise (TV, music, etc.), and make sure no one is talking in the background.`,
                button: 'Try One Last Time',
            },
        ],
        clipping: [
            {
                title: `The recording is too loud.`,
                instructions: `- Consider moving your microphone a bit further away from you.\n - Try speaking a little more quietly.\n - Make sure you are in a quiet place.`,
                button: 'Try Again',
            },
            {
                title: `The recording is still too loud.`,
                instructions: `- Consider moving your microphone a bit further away from you.\n - Try speaking a little more quietly.\n - Make sure you are in a quiet place.`,
                button: 'Try One Last Time',
            },
        ],
    },
    modals: {
        exit: {
            caption: 'Exit Storyteller?',
            text: 'You will be able to come back when you are ready.',
            buttons: {
                confirm: 'Exit',
                cancel: 'No',
            },
        },
        expired: {
            caption: 'Session has expired',
            text: 'You’ve been away for a while, so your session has ended. Press **Restart** to go back to the beginning of Storyteller.',
            buttons: {
                confirm: 'Restart',
                cancel: 'Exit',
            },
        },
        genericError: {
            caption: 'Something went wrong',
            text: 'We apologize for the inconvenience. It seems that something is not working correctly on our end. Please click **Try Again** to continue. If the issue persists, please contact support.',
            buttons: {
                confirm: 'Try Again',
                cancel: 'Exit',
            },
        },
        soundCheck: {
            caption: 'Did you hear the sound?',
        },
        completeStreaming: {
            caption: 'Have you finished?',
        },
    },
    consent: {
        title: 'Consent',
        description: 'Carefully read the consent form and indicate your agreement.',
    },
    recording: 'Recording in progress',
    streaming: {
        play: 'Press to Start',
        playing: 'Listen',
        record: 'Press to Start',
        recordingAutomatic: 'Speak',
        recordingManual: 'Press to Stop',
    },
    soundCheck: {
        audioStart: 'Check Sound',
        audioStop: 'Listen',
    },
}
