.container {
    position: relative;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex: 1;
}

.button_container {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: center;
    flex-grow: 1;
    column-gap: 8px;
}

.modal_button_container {
    display: flex;
    flex-wrap: wrap;
    row-gap: 16px;
    padding: 24px;
    box-shadow: 0px 0px 1px rgba(49, 46, 129, 0.32), 0px 2px 4px -1px rgba(49, 46, 129, 0.08),
        0px 8px 20px -4px rgba(49, 46, 129, 0.12);
    border-radius: 16px;
}

.progress {
    flex-direction: row;
    justify-content: flex-end;
}

.player {
    display: flex;
    flex-grow: 1;
    align-items: flex-start;
    margin: 8px 0;
}

@media only screen and (min-width: 768px) {
    .button_container {
        justify-content: flex-end;
    }
}
