import { Instructions } from './sections/Instructions'

import css from './Checkbox.module.css'
import { NavigationButton } from '../NavigationButton'
import { RegularCaption } from '../typography/Typography'
import { Title } from './sections/Title'
import { List } from './sections/List'
import { useContext } from 'react'
import { ModalContext } from '../../ModalContext'
import { Downloadable } from './sections/Downloadable'
import { Icon } from '../Icon'
import { useTranslation } from 'react-i18next'
import { PromptModalContent } from './PromptModalContent'

export function ConsentQuestion({ onSubmit, onExit, question }: Novoic.CheckboxQuestionProps) {
    const modal = useContext(ModalContext)
    const { t } = useTranslation()
    const { id, question: body } = question
    const { title, accent, instructions, files, icon, list, subtext, checkboxes, canExit } = body

    const state = checkboxes.map((checkbox) => ({
        ...checkbox,
        value: checkbox.default,
        pristine: true,
    }))

    return (
        <div className={css.container}>
            <Icon name={icon} />
            <Title accent={accent}>{title}</Title>
            {subtext && <RegularCaption>{subtext}</RegularCaption>}
            {instructions && <Instructions instructions={instructions} />}
            {list && <List list={list} />}
            {files && <Downloadable list={files} />}

            <div className={css.button_container}>
                {canExit && (
                    <NavigationButton
                        role="cancel"
                        label={t('buttons.consent.confirmLater')}
                        onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                            ;(e.target as HTMLButtonElement).blur()
                            modal.open({
                                render: ({ onConfirm, onCancel }) => (
                                    <PromptModalContent
                                        text={t('modals.exit.text')}
                                        caption={t('modals.exit.caption')}
                                        confirmText={t('modals.exit.buttons.confirm')}
                                        cancelText={t('modals.exit.buttons.cancel')}
                                        onCancel={onCancel}
                                        onConfirm={async () => {
                                            onConfirm()
                                            await onExit()
                                        }}
                                    />
                                ),
                            })
                            return Promise.resolve()
                        }}
                    />
                )}
                <NavigationButton
                    role="confirm"
                    label={body.button}
                    onClick={() =>
                        onSubmit(id, {
                            checkboxes: state.reduce((acc, checkbox) => {
                                acc[checkbox.name] = checkbox.value
                                return acc
                            }, {} as Record<string, boolean>),
                        })
                    }
                />
            </div>
        </div>
    )
}
