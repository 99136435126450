import { Instructions } from './sections/Instructions'

import css from './InterimSuccess.module.css'
import { NavigationButton } from '../NavigationButton'
import { RegularCaption } from '../typography/Typography'
import { Title } from './sections/Title'
import { List } from './sections/List'
import config from '../../config'
import { MobileProgress, DesktopProgress } from '../Progress/Progress'

export function InterimSuccess({ onSubmit, question }: Novoic.CheckboxQuestionProps) {
    const { id, question: body } = question
    const { title, accent, instructions, list, subtext, checkboxes } = body

    const state = checkboxes.map((checkbox) => ({
        ...checkbox,
        value: checkbox.default,
        pristine: true,
    }))

    function onConfirm() {
        return onSubmit(id, {
            checkboxes: state.reduce((acc, checkbox) => {
                acc[checkbox.name] = checkbox.value
                return acc
            }, {} as Record<string, boolean>),
        })
    }

    return (
        <div className={css.container}>
            <MobileProgress />
            <div className={css.title_container}>
                <img className={css.icon} src={config.s3.getStaticURL('success.svg')} />
                <Title accent={accent}>{title}</Title>
            </div>
            {subtext && <RegularCaption>{subtext}</RegularCaption>}
            {instructions && <Instructions instructions={instructions} />}
            {list && <List list={list} />}

            <div className={css.button_container}>
                <DesktopProgress />
                <NavigationButton role="confirm" label={body.button} onClick={onConfirm} />
            </div>
        </div>
    )
}
