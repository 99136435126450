import { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'
import config from '../../../config'
import { Icon } from '../../Icon'
import { RegularCaptionLink } from '../../typography/Typography'

import css from './Downloadable.module.css'

interface DownloadableProps extends PropsWithChildren {
    list: Novoic.File[]
}

export function Downloadable({ list }: DownloadableProps) {
    const { t } = useTranslation()
    return (
        <ul className={css.list}>
            {list.map((item: Novoic.File) => {
                return (
                    <li key={item.icon}>
                        <Icon name={item.icon} />
                        <div className={css.text}>
                            <RegularCaptionLink href={item.link}>
                                {t(item.title)}
                            </RegularCaptionLink>
                        </div>
                    </li>
                )
            })}
        </ul>
    )
}
