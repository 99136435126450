/*
This CSS resource incorporates links to font software which is the valuable copyrighted property of Monotype and/or its suppliers. You may not attempt to copy, install, redistribute, convert, modify or reverse engineer this font software. Please contact Monotype with any questions regarding Web Fonts: http://www.fonts.com
*/

:root {
    --indigo800: #3730a3;
    --indigo700: #4338ca;
    --indigo300: #a5b4fc;
    --indigo200: #c7d2fe;
    --indigo100: #e0e7ff;
    --indigo50: #eef2ff;

    --red800: #991b1b;
    --red700: #b91c1c;
    --red300: #fca5a5;
    --red200: #fecaca;
    --red100: #fee2e2;
    --red50: #fef2f2;

    --neutural900: #171717;
    --neutural500: #737373;
    --neutural400: #d4d4d4;
    --neutural100: #f5f5f5;
    --neutural000: #fff;

    --radius-sm: 8px;
    --radius-md: 12px;
    --radius-lg: 16px;
    --radius-xl: 24px;
}

@supports (font-variation-settings: normal) {
    .container {
        font-family: 'Inter', 'system-ui';
    }
}

h2,
button {
    font-family: 'Inter', 'system-ui';
}

h2 {
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: -0.019em;
    padding: 0;
    margin: 0;
}

strong {
    font-weight: 600;
}

p {
    padding: 0;
    margin: 0;
}

.container {
    margin-top: 16px;
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: -0.011em;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    position: relative;
}

.content {
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 500px;
    overflow: hidden;
    margin: 0 auto;
    width: 100%;
    background: var(--neutural000);
    padding: 24px;
    box-sizing: border-box;
    box-shadow: 0px 0px 1px rgba(49, 46, 129, 0.32), 0px 6px 8px -2px rgba(49, 46, 129, 0.02),
        0px 16px 64px -8px rgba(49, 46, 129, 0.18);
    border-radius: var(--radius-xl);
}

.logo {
    margin: 20px auto 40px;
}

@media only screen and (min-width: 768px) {
    .content {
        width: 744px;
        min-height: 400px;
        padding: 48px;
    }

    .container {
        margin-top: 32px;
    }
}
