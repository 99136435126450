export function useSessionStorage(): [
    <T extends unknown>(key: string, value: T) => void,
    <T extends unknown>(key: string) => T
] {
    function set<T extends unknown>(key: string, value: T) {
        sessionStorage.setItem(key, JSON.stringify(value))
    }

    function get<T extends unknown>(key: string): T | undefined {
        try {
            return JSON.parse(sessionStorage.getItem(key))
        } catch (e: unknown) {
            return undefined
        }
    }

    return [set, get]
}
