.container {
    composes: container from './Button.module.css';
}

.button {
    composes: button from './Button.module.css';
}

.button_text {
    composes: button_text from './Button.module.css';
}

.overlay {
    composes: overlay from './Button.module.css';
}

.player {
    color: var(--indigo800);
    background: linear-gradient(180deg, var(--indigo100) 0%, var(--indigo200) 100%);
}

.player_overlay {
    background-color: var(--indigo700);
}

.player_overlay_icon {
    width: 26px;
    height: 28px;
    margin-left: 8px;
    background-image: url('https://novoic-static.s3.us-west-2.amazonaws.com/play.svg');
}

.player_outside {
    border: 2px solid var(--indigo700);
    padding: 6px;
    border-radius: 50%;
}
