import { useState, Fragment } from 'react'
import { NavigationButton } from '../NavigationButton'

import css from './CustomerSatisfactionQuestion.module.css'
import { RegularCaption } from '../typography/Typography'
import { Instructions } from './sections/Instructions'
import { Title } from './sections/Title'
import { Icon } from '../Icon'

export function CustomerSatisfactionQuestion({ onSubmit, question }: Novoic.ChoiceQuestionProps) {
    const { id, question: body } = question
    const { title, accent, instructions, subtext, choices, button } = body

    const [state, setState] = useState(
        choices.map((choice) => ({
            ...choice,
            value: choice.default,
            pristine: true,
        }))
    )

    async function onSubmitForm() {
        const errors = await onSubmit(id, {
            choices: state.reduce((acc, radio) => {
                acc[radio.name] = radio.value
                return acc
            }, {} as Record<string, string | number>),
        })
    }

    function onChange(name: string, value: string | number) {
        const newState = state.map((radio) => {
            if (radio.name === name) {
                radio.value = value
                radio.pristine = false
            }

            return radio
        })

        setState(newState)
    }

    return (
        <div className={css.container}>
            <Title accent={accent}>{title}</Title>
            {subtext && <RegularCaption>{subtext}</RegularCaption>}
            {instructions && <Instructions instructions={instructions} />}

            <div className={css.choice_container}>
                {state.map((radio) => (
                    <Fragment key={radio.name}>
                        <div className={`${css.item} ${css.normal}`}>
                            <RegularCaption>{radio.title}</RegularCaption>
                            <div className={css.radio_container}>
                                {radio.options.map((option) => (
                                    <button
                                        className={`${css.button} ${
                                            radio.value === option.value ? css.selected : ''
                                        }`}
                                        key={option.title}
                                        onClick={() => onChange(radio.name, option.value)}
                                    >
                                        <input
                                            id={radio.name + option.title}
                                            className={css.radio_input}
                                            type="radio"
                                            value={option.value}
                                            checked={radio.value === option.value}
                                            name={radio.name}
                                            onChange={() => onChange(radio.name, option.value)}
                                        />
                                        <label htmlFor={radio.name + option.title}>
                                            <Icon
                                                name={
                                                    `satisfied${option.title}` as keyof typeof Novoic.SupportedIcons
                                                }
                                            />
                                        </label>
                                    </button>
                                ))}
                            </div>
                        </div>
                        <div className={css.label_container}>
                            <span className={css.low_label}>Not at all</span>
                            <span className={css.high_label}>Very much</span>
                        </div>
                    </Fragment>
                ))}
            </div>

            <div className={css.button_container}>
                <NavigationButton role="confirm" label={button} onClick={onSubmitForm} />
            </div>
        </div>
    )
}
