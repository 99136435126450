import css from './RecordingButton.module.css'
import { Card } from '../Card'
import { useEffect, useMemo, useState } from 'react'
import { useRecordingAnimation } from '../../hooks/useRecordingAnimation'
import { useProgress } from '../../hooks/useProgress'
import { useTranslation } from 'react-i18next'

interface RecordingButtonProps {
    label: string
    analyser: AnalyserNode
    isDisabled: boolean
    maxRecordingTime?: number
    onClick: () => void
}

export function RecordingButton(props: RecordingButtonProps) {
    const [micLevelCanvas, setMicLevelCanvas] = useState<HTMLCanvasElement>()
    const [progressCanvas, setProgressCanvas] = useState<HTMLCanvasElement>()
    const [startAnimation, _stopAnimation] = useRecordingAnimation(props.analyser, micLevelCanvas)

    const { t } = useTranslation()

    const controls = useMemo(
        () =>
            useProgress({
                canvas: progressCanvas,
                color: '#B91C1C',
                duration: props.maxRecordingTime * 1000,
                onEnd: () => {},
            }),
        [progressCanvas]
    )

    function onMicLevelRef(node: HTMLCanvasElement) {
        setMicLevelCanvas(node)
    }

    function onProgressRef(node: HTMLCanvasElement) {
        setProgressCanvas(node)
    }

    useEffect(() => {
        if (props.analyser && micLevelCanvas) {
            startAnimation()
        }
    }, [props.analyser, micLevelCanvas])

    useEffect(() => {
        if (progressCanvas && controls) {
            controls.start()
        }
    }, [controls, progressCanvas])

    return (
        <div className={css.container}>
            <canvas
                className={css.mic_level_canvas}
                ref={onMicLevelRef}
                width={256 * devicePixelRatio}
                height={256 * devicePixelRatio}
            />
            {props.maxRecordingTime && (
                <canvas
                    className={css.progress_canvas}
                    ref={onProgressRef}
                    width={130 * devicePixelRatio}
                    height={130 * devicePixelRatio}
                />
            )}
            <div
                className={
                    props.maxRecordingTime ? css.recorder_outside_timer : css.recorder_outside
                }
            >
                <button
                    disabled={props.isDisabled}
                    onClick={props.onClick}
                    className={`${css.button} ${
                        props.maxRecordingTime ? css.recorder_timer : css.recorder
                    }`}
                >
                    <p className={css.button_text}>{props.label}</p>
                    <div className={`${css.overlay} ${css.recorder_overlay}`}>
                        <div className={css.recorder_overlay_icon} />
                    </div>
                </button>
            </div>
            <Card className={css.card} variant="medium" animate>
                <div className={css.recording_tile_content}>
                    <div className={css.recording_circle_background}>
                        <div className={css.recording_circle} />
                    </div>
                    <span>{t('recording')}</span>
                </div>
            </Card>
        </div>
    )
}
