.flexible {
    min-height: 128px;
}

.accent {
    font-family: 'Gelica W01 SemiBold';
    letter-spacing: normal;
    font-size: 32px;
    line-height: 36px;
}

@media only screen and (min-width: 768px) {
    .flexible {
        min-height: 64px;
    }
}
