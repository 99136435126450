import { useState } from 'react'
import { Instructions } from './sections/Instructions'
import css from './Audio.module.css'
import { StreamingRecorder, StreamingState } from '../StreamingRecorder/StreamingRecorder'
import { Title } from './sections/Title'
import { RegularCaption } from '../typography/Typography'
import { NavigationButton } from '../NavigationButton'
import { useQCErrors } from '../../hooks/useQCErrors'
import { MobileProgress, DesktopProgress } from '../Progress/Progress'
import { useTranslation } from 'react-i18next'

function mapError(error: string): { title: string; instructions: string; button: string }[] {
    switch (error) {
        case 'wer':
            return [
                {
                    title: `Uh-oh. We didn’t catch what you said there.`,
                    instructions: `- Remember the recording begins automatically.\n - Make sure you repeat the sentence and nothing else.\n - Press the stop button when you have finished.`,
                    button: 'Try Again',
                },
                {
                    title: `Hmm. We still didn’t catch what you said there. `,
                    instructions: `- Remember the recording begins automatically.\n - Make sure you repeat the sentence and nothing else.\n - Press the stop button when you have finished.`,
                    button: 'Try One Last Time',
                },
            ]
        case 'noise':
            return [
                {
                    title: `Uh-oh, it sounds like there is some background noise.`,
                    instructions: `Please find a quieter place or switch off anything that is making noise (TV, music, etc.), and make sure no one is talking in the background.`,
                    button: 'Try Again',
                },
                {
                    title: `Hmm, it sounds like there is still some background noise.`,
                    instructions: `Please find a quieter place or switch off anything that is making noise (TV, music, etc.), and make sure no one is talking in the background.`,
                    button: 'Try One Last Time',
                },
            ]
        case 'clipping':
            return [
                {
                    title: `The recording is too loud.`,
                    instructions: `- Consider moving your microphone a bit further away from you.\n - Try speaking a little more quietly.\n - Make sure you are in a quiet place.`,
                    button: 'Try Again',
                },
                {
                    title: `The recording is still too loud.`,
                    instructions: `- Consider moving your microphone a bit further away from you.\n - Try speaking a little more quietly.\n - Make sure you are in a quiet place.`,
                    button: 'Try One Last Time',
                },
            ]
    }
}

function getError(errors: Record<string, string>): Novoic.ValidationError {
    const name = Object.keys(errors)[0]
    const error = errors[name]
    return {
        name,
        error,
    }
}

function getTitle(body: Novoic.AudioQuestionBody, state: StreamingState): string {
    switch (state) {
        case StreamingState.IDLE:
        case StreamingState.LOADING:
            return body.title
        case StreamingState.PLAYING:
            return body.titlePlaying
        case StreamingState.STREAMING:
            return body.titleRecording
        case StreamingState.PROCESSING:
        default:
            return ''
    }
}

export function QCQuestion({ onSubmit, question }: Novoic.AudioQuestionProps) {
    const { id, question: body } = question
    const {
        instructions,
        subtext,
        audio,
        recordingStartMode,
        recordingStopMode,
        maxRecordingTime,
    } = body

    const [store, retrieve] = useQCErrors(id)
    const [storedAttempt, storedErrors, errorCount] = retrieve()
    const [attempt, setAttempt] = useState<number>(storedAttempt)
    const [state, setState] = useState<StreamingState>(StreamingState.IDLE)
    const [showErrors, setShowErrors] = useState<boolean>(Boolean(storedErrors))
    const { t } = useTranslation()

    /**
     * Submit when streaming is finished
     */
    async function onStreamingEnded(recording: string) {
        const errors = await onSubmit(id, { recording, skip: attempt === 2 })
        if (errors) {
            store(attempt, getError(errors))
            setState(StreamingState.IDLE)
            setAttempt(attempt + 1)
            setShowErrors(true)
        }
    }

    /**
     * Change the state of the streaming so UI can be updated based on it
     */
    function onStateChange(state: StreamingState) {
        setState(state)
    }

    if (showErrors && storedErrors) {
        const idx = errorCount - 1
        const title = t(`errors.${storedErrors.name}.${idx}.title`)
        const instructions = t(`errors.${storedErrors.name}.${idx}.instructions`)
        const button = t(`errors.${storedErrors.name}.${idx}.button`)

        return (
            <div className={css.container}>
                <Title>{title}</Title>
                <Instructions instructions={instructions} />

                <div className={css.button_container}>
                    <NavigationButton
                        role="confirm"
                        label={button}
                        onClick={() => {
                            setShowErrors(false)
                            return Promise.resolve()
                        }}
                    />
                </div>
            </div>
        )
    }

    return (
        <div className={css.container}>
            <MobileProgress />
            <Title flexible>{getTitle(body, state)}</Title>
            {subtext && <RegularCaption>{subtext}</RegularCaption>}
            {instructions && <Instructions instructions={instructions} />}

            <StreamingRecorder
                attempt={attempt}
                onStreamingEnded={onStreamingEnded}
                onStateChange={onStateChange}
                audio={audio}
                recordingStartMode={recordingStartMode}
                recordingStopMode={recordingStopMode}
                maxRecordingTime={maxRecordingTime}
            />

            <div className={css.progress}>
                <DesktopProgress />
            </div>
        </div>
    )
}
