import { useEffect, useState } from 'react'

/**
 * Unfortunately, we need to keep this state outside of the component cycle emuleting
 * a global variable/state. This is required because React's state works in a way that
 * it takes a snapshot of the current state while executing a hook. This means that at
 * the moment when we need to cancel our animation frame the reference to the frame
 * is already outdated. In order to be able to refer to the *latest* animation frame,
 * we need to keep the reference outside of the state and preserve it during component
 * re-rendering cycle. Hence, the variable lives within the module scope, but outside
 * of the component
 */
let raf: number

/**
 * useRecordingAnimation animates given canvas with a circular equalizer
 * @private
 * @param media Novoic.MediaObject Object containing media infromation for animation
 * @param canvas HTMLCanvasElement Canvas for the animation
 * @returns [Function, Function] Tuple with start and stop functions
 */
export function useRecordingAnimation(
    analyser: AnalyserNode,
    canvas: HTMLCanvasElement
): [() => void, () => void] {
    const [isAnimating, setIsAnimating] = useState(false)

    useEffect(() => {
        if (!isAnimating && canvas) {
            setTimeout(() => {
                cancelAnimationFrame(raf)
            }, 2000)
        }
    }, [isAnimating])

    function animate() {
        if (!canvas || !analyser) {
            return
        }

        const coef = devicePixelRatio / 2
        const ctx = canvas.getContext('2d')
        ctx.clearRect(0, 0, canvas.width, canvas.height)

        // Read current frequency data to the dataArray
        const bufferLength = analyser.frequencyBinCount
        const dataArray = new Uint8Array(bufferLength)
        analyser.getByteFrequencyData(dataArray)

        // Looking for max frequency here
        let max = dataArray.reduce((prev, cur) => Math.max(prev, cur), 0) * 0.8 * coef

        ctx.save()
        ctx.fillStyle = `rgba(254, 202, 202, ${1.7 - max / (128 * coef)})`
        ctx.translate(canvas.width / 2, canvas.height / 2)
        ctx.beginPath()
        ctx.arc(0, 0, max, 0, Math.PI * 2)
        ctx.fill()
        ctx.restore()

        ctx.fillStyle = '#fff'

        ctx.save()
        ctx.translate(canvas.width / 2, canvas.height / 2)
        ctx.beginPath()
        ctx.arc(0, 0, 128 * coef, 0, Math.PI * 2)
        ctx.fill()
        ctx.restore()

        raf = requestAnimationFrame(animate)
    }

    function start() {
        setIsAnimating(true)
        animate()
    }

    function stop() {
        setIsAnimating(false)
    }

    return [start, stop]
}
