@keyframes recording {
    0% {
        opacity: 0;
    }
    5% {
        opacity: 0;
    }
    10% {
        opacity: 1;
    }
    80% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.container {
    composes: container from './Button.module.css';
}

.card {
    border-radius: 12px;
}

.button {
    composes: button from './Button.module.css';
}

.button_text {
    composes: button_text from './Button.module.css';
}

.overlay {
    composes: overlay from './Button.module.css';
}

.mic_level_canvas {
    width: 256px;
    height: 256px;
    position: absolute;
    top: -64px;
}

.progress_canvas {
    width: 130px;
    height: 130px;
    position: absolute;
    top: -1px;
}

.recorder {
    color: var(--red700);
    background: linear-gradient(180deg, var(--red100) 0%, var(--red200) 100%);
}

.recorder_timer {
    color: var(--red700);
    background-color: var(--red50);
}

.recorder_overlay {
    background-color: var(--red700);
}

.recorder_overlay_icon {
    width: 26px;
    height: 26px;
    background-image: url('https://novoic-static.s3.us-west-2.amazonaws.com/stop.svg');
}

.recorder_outside {
    border: 2px solid var(--red700);
    padding: 6px;
    border-radius: 50%;
    position: relative;
}

.recorder_outside_timer {
    padding: 8px;
    position: relative;
}

.recording_circle_background {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: var(--red100);
}

.recording_circle {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin: 4px;
    background-color: var(--red700);
    animation: recording 2s ease-out infinite;
}

.recording_tile_content {
    padding: 8px 12px;
    display: flex;
    align-items: center;
    gap: 8px;
}
