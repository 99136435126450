import { useContext, useState } from 'react'
import { AudioPlayer } from '../AudioPlayer/AudioPlayer'

import css from './SoundCheck.module.css'
import { RegularCaption } from '../typography/Typography'
import { Title } from './sections/Title'
import { ModalContext } from '../../ModalContext'
import { MobileProgress, DesktopProgress } from '../Progress/Progress'
import { useTranslation } from 'react-i18next'
import { PromptModalContent } from './PromptModalContent'

export function SoundCheckQuestion({ onSubmit, question }: Novoic.CheckboxQuestionProps) {
    const { id, question: body } = question
    const { title, subtext, audio } = body as Novoic.SoundCheckQuestionBody

    const [isDisabled, setIsDisabled] = useState<boolean>(false)
    const { t } = useTranslation()

    const modal = useContext(ModalContext)

    function onConfirm() {
        setIsDisabled(true)
        return onSubmit(id, {
            checkboxes: {
                'can-hear-sound': true,
            },
        })
    }

    function onClose() {
        setIsDisabled(false)
        return Promise.resolve()
    }

    function onNoSound() {
        setIsDisabled(true)
        return onSubmit(id, {
            checkboxes: {
                'can-hear-sound': false,
            },
        })
    }

    return (
        <div className={css.container}>
            <MobileProgress />
            {title && <Title flexible>{title}</Title>}
            {subtext && <RegularCaption>{subtext}</RegularCaption>}

            <div className={css.player}>
                <AudioPlayer
                    audio={audio}
                    titles={{ start: t('soundCheck.audioStart'), stop: t('soundCheck.audioStop') }}
                    isDisabled={isDisabled}
                    onEnded={() => {
                        modal.open({
                            render: ({ onConfirm: defaultConfirm, onCancel: defaultCancel }) => (
                                <PromptModalContent
                                    caption={t('modals.soundCheck.caption')}
                                    onConfirm={async () => {
                                        await onConfirm()
                                        defaultConfirm()
                                    }}
                                    onCancel={async () => {
                                        await onNoSound()
                                        defaultCancel()
                                    }}
                                />
                            ),
                            onClose,
                        })
                    }}
                />
            </div>
            <div className={css.progress}>
                <DesktopProgress />
            </div>
        </div>
    )
}
