.container {
    margin: 4px 0 0;
}

.container h2 {
    min-height: 128px;
}

.container ul {
    padding: 0;
    margin: 0 0 0 20px;
    display: flex;
    gap: 12px;
    flex-direction: column;
}

.container img {
    vertical-align: middle;
}

.container li {
    padding-left: 8px;
}

.instructions {
    padding: 20px;
    background-color: var(--indigo50);
    border-radius: 12px;
    font-weight: 300;
}

.instructions h4 {
    font-weight: 500;
    margin: 0 0 12px;
}

.instructions h4:not(:first-of-type) {
    margin: 20px 0 12px;
}

.instructions b,
.instructions strong {
    font-weight: 500;
}

.instructions img {
    display: inline;
}

.spacer {
    height: 16px;
    margin: 0;
}

@media only screen and (min-width: 768px) {
    .container h2 {
        min-height: 64px;
    }
}
