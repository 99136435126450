import { useEffect, useState } from 'react'

import css from './CAT.module.css'
import { StreamingRecorder, StreamingState } from '../StreamingRecorder/StreamingRecorder'
import { RegularCaption } from '../typography/Typography'
import { Instructions } from './sections/Instructions'
import { Title } from './sections/Title'
import { MobileProgress, DesktopProgress } from '../Progress/Progress'
import { useTranslation } from 'react-i18next'

function mapError(error: string): string {
    switch (error) {
        case 'session':
            return `You are very quiet!\n\n
            Please speak louder or move your device closer and try again            
            `
        case 'wer':
            return `We didn’t catch what you said there!\n\n
            Please listen to the instructions carefully and try again.\n            
            Remember the recording begins automatically. Press the stop button when you have finished.
            `
    }
}

function getTitle(body: Novoic.AudioQuestionBody, state: StreamingState): string {
    switch (state) {
        case StreamingState.IDLE:
        case StreamingState.LOADING:
            return body.title
        case StreamingState.PLAYING:
            return body.titlePlaying
        case StreamingState.STREAMING:
            return body.titleRecording
        case StreamingState.PROCESSING:
        default:
            return ''
    }
}

export function CATQuestion({ onSubmit, question, errors = [] }: Novoic.AudioQuestionProps) {
    const { id, question: body } = question
    const {
        instructions,
        subtext,
        audio,
        recordingStartMode,
        recordingStopMode,
        maxRecordingTime,
    } = body

    const [attempt, setAttempt] = useState<number>(1)
    const [state, setState] = useState<StreamingState>(StreamingState.IDLE)
    const { t } = useTranslation()

    /**
     * Submit when streaming is finished
     */
    function onStreamingEnded(recording: string) {
        onSubmit(id, { recording })
    }

    /**
     * Change the state of the streaming so UI can be updated based on it
     */
    function onStateChange(state: StreamingState) {
        setState(state)
    }

    /**
     * Reset the state of the audio question component
     */
    useEffect(() => {
        if (errors.length > 0) {
            setState(StreamingState.IDLE)
            setAttempt((current) => current + 1)
        }
    }, [errors])

    return (
        <div className={css.container}>
            <MobileProgress />
            <Title flexible>{getTitle(body, state)}</Title>
            {subtext && <RegularCaption>{subtext}</RegularCaption>}

            <StreamingRecorder
                attempt={attempt}
                onStreamingEnded={onStreamingEnded}
                onStateChange={onStateChange}
                audio={audio}
                titles={{
                    recording: t('streaming.recordingAutomatic'),
                }}
                recordingStartMode={recordingStartMode}
                recordingStopMode={recordingStopMode}
                maxRecordingTime={maxRecordingTime}
            />

            {(state === StreamingState.IDLE || state === StreamingState.LOADING) && (
                <Instructions instructions={instructions} />
            )}

            <div className={css.progress}>
                <DesktopProgress />
            </div>
        </div>
    )
}
