import { PropsWithChildren, useState } from 'react'
import { ModalConfig, ModalAPI, ModalContext } from '../ModalContext'
import { Modal } from './questions/Modal'
import { CSSTransition } from 'react-transition-group'
import css from './GlobalModalContext.module.css'
import { useActivityPing } from '../hooks/useActivityPing'

export function GlobalModalContext({ children }: PropsWithChildren) {
    const ping = useActivityPing()
    const [modalConfig, setModalConfig] = useState<ModalConfig>({
        render: () => <></>,
    })

    const [isOpen, setIsOpen] = useState<boolean>(false)

    const API: ModalAPI = {
        async open(modalConfig: ModalConfig) {
            setModalConfig(modalConfig)
            setIsOpen(true)
        },
        async close() {
            setIsOpen(false)
            await ping()
        },
    }

    function onConfirm() {
        setIsOpen(false)
        return Promise.resolve()
    }

    function onCancel() {
        setIsOpen(false)
        return Promise.resolve()
    }

    function onClose() {
        modalConfig.onClose?.()
        setIsOpen(false)
        return Promise.resolve()
    }

    return (
        <ModalContext.Provider value={API}>
            {children}
            <CSSTransition
                in={isOpen}
                timeout={300}
                mountOnEnter
                unmountOnExit
                classNames={{
                    enter: css.transition_enter,
                    enterActive: css.transition_enter_active,
                    exit: css.transition_exit,
                    exitActive: css.transition_exit_active,
                }}
            >
                <Modal
                    render={modalConfig.render}
                    canClose={modalConfig.canClose}
                    onConfirm={onConfirm}
                    onCancel={onCancel}
                    onClose={onClose}
                />
            </CSSTransition>
        </ModalContext.Provider>
    )
}
