import { ModalConfig } from '../../ModalContext'
import css from './Modal.module.css'

Modal.defaultProps = {
    confirmText: 'buttons.yes',
    cancelText: 'buttons.no',
    canClose: true,
}

export function Modal(props: ModalConfig) {
    return (
        <div className={css.backdrop}>
            <div className={css.modal}>
                {props.canClose && (
                    <img
                        src="https://novoic-static.s3.us-west-2.amazonaws.com/close.svg"
                        role="button"
                        className={css.closeIcon}
                        onClick={props.onClose}
                    />
                )}
                {props.render(props)}
            </div>
        </div>
    )
}
