import css from './Icon.module.css'
import config from '../config'

const ICON_WIDTH = 32

function calcIconOffset(index: number): string {
    return `calc(-${ICON_WIDTH * index}px - ${ICON_WIDTH * index * 0.75}px) 0px`
}

const ICONS = [
    'quiet-place',
    'have-time',
    'on-my-own',
    'no-notes',
    'have-aid',
    'sound',
    'headset',
    'bluetooth',
    'file',
    'hourglass',
    'satisfied1',
    'satisfied2',
    'satisfied3',
    'satisfied4',
    'satisfied5',
]

interface IconProps extends React.HTMLAttributes<HTMLImageElement> {
    name: keyof typeof Novoic.SupportedIcons
}

export function Icon(props: IconProps) {
    return (
        <img
            className={css.icon}
            style={{ objectPosition: calcIconOffset(ICONS.indexOf(props.name)) }}
            src={config.s3.getStaticURL('icons.svg')}
        />
    )
}
