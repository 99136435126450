import css from './Card.module.css'

interface CardProps extends React.HTMLAttributes<HTMLDivElement> {
    variant: 'low' | 'medium' | 'high'
    animate: boolean
}

export function Card({ variant, animate, children, className }: CardProps) {
    return (
        <div className={`${css.card} ${css[variant]} ${animate && css.animate} ${className}`}>
            {children}
        </div>
    )
}
