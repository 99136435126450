import axios from 'axios'
import config from './config'
import { EventEmitter } from 'eventemitter3'
import { Deferred } from './Deferred'
import { isMobile } from './isMobile'

export class WebRTCStreamer {
    /**
     * RCTPeerConnection instance
     */
    #conn: RTCPeerConnection

    /**
     * RTCDataChannel to transmit events in real-time
     */
    #dc: RTCDataChannel

    /**
     * Session description that was issued to us by the backend
     */
    #sdp: RTCSessionDescriptionInit

    /**
     * Recording identification
     */
    #recordingUUID: Promise<string>

    /**
     * Deferred object that resolves with a session UUID
     */
    #session: Deferred<string> = new Deferred()

    /**
     * Deferred object to indicate if the steramer is in the connected state
     */
    #isConnected: Deferred<boolean> = new Deferred()

    /**
     * Event Emitter to communicate back the "track" event
     */
    ee = new EventEmitter()

    constructor(stream: MediaStream, conf: RTCConfiguration) {
        this.#connect(stream, conf)
    }

    #onTrack = (ev: RTCTrackEvent) => {
        const [remoteStream] = ev.streams
        this.ee.emit('track', remoteStream)
    }

    #onMessage = (ev: MessageEvent<string>) => {
        switch (ev.data) {
            case 'play.start':
                this.ee.emit('track.start')
                break
            case 'play.end':
                this.ee.emit('track.end')
                this.#recordingUUID = undefined
                break
        }
    }

    #onIceCandidate = async (e: RTCPeerConnectionIceEvent) => {
        if (e.candidate === null) {
            const localDescription = JSON.stringify({
                description: this.#conn.localDescription.toJSON(),
                language: 'en-US',
            })

            try {
                const { data } = await axios.post(config.webRTC.urls.sessions, localDescription)
                this.#sdp = data.description

                const description = new RTCSessionDescription(this.#sdp)
                await this.#conn.setRemoteDescription(description)
                this.#session.resolve(data.session)
            } catch (e) {
                console.error(e)
                this.#session.reject(e)
            }
        }
    }

    #onDataChannelOpen = (ev: Event) => {
        this.#isConnected.resolve(true)
        this.ee.emit('connected')
        console.timeEnd('connected')
    }

    /**
     * Establishes a WebRTC connection using the given media stream.
     * This connection is established in multiple steps:
     * 1. Form the RTCPeerConnection
     * 2. Add stream tracks to the connection. Connection without tracks won't be established
     * 3. Get the local descriptor and register it with the WebRTC server to get the session id
     *
     * @param {MediaStream} stream
     */
    async #connect(stream: MediaStream, rtcConfig: RTCConfiguration) {
        console.time('connected')
        this.#conn = new RTCPeerConnection(rtcConfig)
        this.#dc = this.#conn.createDataChannel('data')

        this.#conn.getSenders().forEach((sender) => {
            sender.getParameters().encodings.forEach((encoding) => {
                delete encoding.maxBitrate
            })
        })

        if (isMobile) {
            this.#conn.addTransceiver('video', {
                direction: 'recvonly',
            })

            this.#conn.addTransceiver('audio', {
                direction: 'sendrecv',
            })
        }

        this.#dc.addEventListener('open', this.#onDataChannelOpen)
        this.#dc.addEventListener('message', this.#onMessage)

        stream.getTracks().forEach((track: MediaStreamTrack) => {
            this.#conn.addTrack(track, stream)
        })

        this.#conn.addEventListener('track', this.#onTrack)
        this.#conn.addEventListener('icecandidate', this.#onIceCandidate)

        const desc: RTCSessionDescriptionInit = await this.#conn.createOffer({
            iceRestart: true,
        })
        // const sdp = desc.sdp?.replace('useinbandfec=1', 'useinbandfec=1; maxaveragebitrate=510000')
        await this.#conn.setLocalDescription(desc)
    }

    async start(): Promise<string> {
        await this.#isConnected.promise
        if (this.#recordingUUID) {
            return Promise.reject('Streaming in progress')
        }
        this.#recordingUUID = new Promise<string>(async (resolve) => {
            try {
                console.time('create a recording')
                const session = await this.#session.promise
                const { data } = await axios.post(config.webRTC.urls.recordings, {
                    language: 'en-US',
                    session,
                })
                console.timeEnd('create a recording')
                console.log(
                    'recording link: ',
                    `${config.webRTC.urls.recordings}/${data.recording}/media`
                )

                resolve(data.recording)
            } catch (e) {
                console.error('Failed to establish a new session and start recording:', e)
            }
        })

        return this.#recordingUUID
    }

    async stop(): Promise<void> {
        const recordingUUID = await this.#recordingUUID
        await axios.delete(config.webRTC.urls.recordings + '/' + recordingUUID)

        this.#conn.removeEventListener('icecandidate', this.#onIceCandidate)
        this.#conn.removeEventListener('track', this.#onTrack)

        this.#dc.removeEventListener('open', this.#onDataChannelOpen)
        this.#dc.removeEventListener('message', this.#onMessage)

        if (isMobile) {
            this.#conn.getTransceivers().map((transceiver) => transceiver.stop())
        }

        this.#conn.close()

        this.#conn.getSenders().forEach(({ track }) => {
            if (track?.readyState == 'live') {
                track.stop()
            }
        })
        this.#conn = undefined
        this.#sdp = undefined
    }

    async play(audio: string) {
        await this.#isConnected.promise
        console.time('playing an audio: ' + audio)
        const session = await this.#session.promise
        await axios.post(`${config.webRTC.urls.sessions}/${session}/play`, {
            file: audio,
        })
        console.timeEnd('playing an audio: ' + audio)
    }
}
