import { AudioQuestion } from './Audio'
import { CATQuestion } from './CAT'
import { QCQuestion } from './QC'

export function AudioQuestionFactory(props: Novoic.AudioQuestionProps) {
    const { question } = props.question
    switch (question.category) {
        case 'qc':
            return <QCQuestion {...props} />
        case 'asrt':
            return <AudioQuestion {...props} />
        case 'cat':
            return <CATQuestion {...props} />
    }
}
