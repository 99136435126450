import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import css from './NavigationButton.module.css'

interface ButtonProps {
    label: string
    role: 'confirm' | 'cancel'
    isDisabled?: boolean
    onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => Promise<unknown>
}

export function NavigationButton(props: ButtonProps) {
    const [isProcessing, setIsProcessing] = useState<boolean>(false)
    const { t } = useTranslation()

    /**
     * Button stays disabled only if it is a submit button and it is
     * either disabled through props or through the local processing state
     */
    const isButtonDisabled = props.role === 'confirm' && (props.isDisabled || isProcessing)

    async function onClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        setIsProcessing(true)
        await props.onClick(e)
        setTimeout(() => {
            setIsProcessing(false)
        }, 300)
    }

    return (
        <button
            disabled={isButtonDisabled}
            onClick={onClick}
            className={`${css.button} ${css[props.role]}`}
        >
            {t(props.label)}
        </button>
    )
}
