.container {
    display: flex;
    flex: 1;
    flex-direction: column;
    min-height: 100%;
    gap: 16px;
}

.error {
    outline: 6px solid var(--red300);
}

.spacer {
    display: flex;
}

.horizontal {
    display: flex;
    flex-direction: row;
}

.item {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.choice_container {
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin: 16px 0;
}

.radio_container {
    position: relative;
    display: flex;
    align-items: center;
    border-radius: 8px;
}

.radio_input {
    margin: 0 16px 0 0;
    width: 24px;
    height: 24px;
}

.error .radio_input {
    appearance: none;
    border-radius: 50%;
    border: 2px solid var(--red700);
}

.error_message_container {
    text-align: center;
    border-radius: 12px;
    background-color: var(--red50);
    padding: 6px 24px;
}

.error_message {
    color: var(--red700);
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
}

.button_container {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    gap: 8px;
    margin: 8px 0 0;
    flex-direction: column-reverse;
    margin-top: auto;
}

.button {
    display: flex;
    text-align: left;
    color: #000;
    flex-grow: 1;
    accent-color: var(--indigo700);
    background-color: var(--neutural000);
    border: 1px solid var(--neutural400);
    border-radius: 8px;
    padding: 16px 24px;
    margin: 0;
    outline: none !important;
    transition: all 200ms;
}

.button:first-of-type {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 1px solid transparent;
}

.button:last-of-type {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.button:not(:first-child):not(:last-child) {
    border-radius: 0;
    border-right: 1px solid transparent;
}

.progress {
    flex-direction: row;
    justify-content: flex-end;
}

@media only screen and (min-width: 768px) {
    .button {
        flex-grow: 0;
    }

    .button_container {
        margin-top: 16px;
        flex-direction: row;
        justify-content: flex-end;
    }

    .error_message_container {
        width: 315px;
    }
}
