import { useEffect } from 'react'
import { useSessionStorage } from './useSessionStorage'

interface QCError {
    error: Novoic.ValidationError
    count: number
}

type Attempt = number
type Count = number

export function useQCErrors(
    id: string
): [
    (attempt: number, error: Novoic.ValidationError) => void,
    () => [number, Novoic.ValidationError, number]
] {
    const [persist, retrieve] = useSessionStorage()

    function set(attempt: number, error: Novoic.ValidationError = undefined) {
        const count = retrieve<number>(`${id}/${error.name}`)
        if (!count) {
            persist(`${id}/${error.name}`, 1)
        } else {
            persist(`${id}/${error.name}`, count + 1)
        }

        persist(id, attempt + 1)

        persist<QCError>(`${id}/${attempt}`, {
            error,
            count: count + 1,
        })
    }

    function get(): [Attempt, Novoic.ValidationError, Count] {
        const attempt = retrieve<number>(id)
        const data = retrieve<QCError>(`${id}/${attempt - 1}`)

        if (data) {
            const { error, count } = data
            if (!error) {
                return [attempt, undefined, 0]
            }

            return [attempt, error, count]
        }

        return [0, undefined, 0]
    }

    return [set, get]
}
