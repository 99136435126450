.container {
    position: relative;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 16px;
}

.button_container {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    gap: 8px;
    margin: 8px 0 0;
    flex-direction: column-reverse;
    margin-top: auto;
}

.title_container {
    display: flex;
    gap: 12px;
}

.icon {
    width: 32px;
    height: 32px;
}

.button_container button {
    width: 100%;
}

@media only screen and (min-width: 768px) {
    .button_container {
        justify-content: flex-end;
        flex-direction: row;
    }

    .button_container button {
        width: auto;
    }
}
