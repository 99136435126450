import { AudioQuestionFactory } from './questions/AudioQuestionFactory'
import { CheckboxQuestionFactory } from './questions/CheckboxQuestionFactory'
import { SwitchTransition, CSSTransition } from 'react-transition-group'

import css from './Question.module.css'
import { useContext, useEffect } from 'react'
import { ModalContext } from '../ModalContext'
import { useSessionStorage } from '../hooks/useSessionStorage'
import { MiddlewareContext } from '../MiddlewareContext'
import { useAxiosMiddleware } from '../hooks/useAxiosMiddleware'
import { ChoiceQuestionFactory } from './questions/ChoiceQuestionFactory'
import { useTranslation } from 'react-i18next'
import { PromptModalContent } from './questions/PromptModalContent'

/**
 * Mirror for global.d.ts QuestionType enum
 * Since d.ts files are for the compile time only,
 * we can't refer to its values through the Novoic.* interface
 *
 * In order to use these enums as values, we need to create
 * a mirror, runtime-friendly enum like this one.
 */
enum QuestionType {
    AUDIO = 'audio',
    CHECKBOX = 'checkbox',
    CHOICE = 'choice',
}

/**
 * Routing component that renders a question based on its type
 * @param {QuestionProps} props Props wrapper for question object and submit handler
 * @returns {JSX.Element}
 */
export function Question(): JSX.Element {
    let content: JSX.Element

    let { question, isExpired, submit, exit, errors, onTimeout } = useContext(MiddlewareContext)

    useAxiosMiddleware()

    const modal = useContext(ModalContext)
    const [set, get] = useSessionStorage()
    const { t } = useTranslation()

    useEffect(() => {
        if (isExpired) {
            modal.open({
                render: ({ onCancel, onConfirm }) => (
                    <PromptModalContent
                        text={t('modals.expired.text')}
                        caption={t('modals.expired.caption')}
                        confirmText={t('modals.expired.buttons.confirm')}
                        cancelText={t('modals.expired.buttons.cancel')}
                        onCancel={async () => {
                            await exit()
                            onCancel()
                        }}
                        onConfirm={async () => {
                            await onTimeout()
                            onConfirm()
                        }}
                    />
                ),
                onClose: exit,
            })
        }
    }, [isExpired])

    if (!question) {
        question = get('lastQuestion')
        if (!question) {
            return null
        }
    }

    switch (question?.type) {
        case QuestionType.AUDIO:
            content = (
                <AudioQuestionFactory
                    errors={errors}
                    question={question as Novoic.AudioQuestion}
                    onSubmit={submit}
                    onExit={exit}
                />
            )
            break

        case QuestionType.CHECKBOX:
            content = (
                <CheckboxQuestionFactory
                    errors={errors}
                    question={question as Novoic.CheckboxQuestion}
                    onSubmit={submit}
                    onExit={exit}
                />
            )
            break

        case QuestionType.CHOICE:
            content = (
                <ChoiceQuestionFactory
                    errors={errors}
                    question={question as Novoic.ChoiceQuestion}
                    onSubmit={submit}
                    onExit={exit}
                />
            )
            break
        default:
            content = <div>Expired</div>
    }

    return (
        <SwitchTransition>
            <CSSTransition
                key={question?.id}
                timeout={300}
                mountOnEnter
                unmountOnExit
                classNames={{
                    enter: css.transition_enter,
                    enterActive: css.transition_enter_active,
                    exit: css.transition_exit,
                    exitActive: css.transition_exit_active,
                }}
            >
                {content}
            </CSSTransition>
        </SwitchTransition>
    )
}
