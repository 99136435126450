import { createContext } from 'react'

export interface Middleware {
    assignmentId: string
    question?: Novoic.Question<unknown>
    errors?: Record<string, any>
    isExpired: boolean
    onTimeout?: () => Promise<void>
    submit: (questionID: string, answer: Record<string, any>) => Promise<Record<string, any> | void>
    exit: () => Promise<void>
}

export const MiddlewareContext = createContext<Middleware>(undefined)
