.button {
    flex-grow: 1;
    border-radius: 12px;
    padding: 16px 32px;
    font-size: 20px;
    font-family: 'Inter', 'system-ui';
    border: none;
    cursor: pointer;
    position: relative;
    box-sizing: border-box;
}

.button:focus {
    outline: 0;
}

.confirm:disabled {
    background-color: var(--indigo100);
}

.confirm {
    background-color: var(--indigo700);
    color: white;
}

.cancel {
    background-color: var(--neutural000);
    border: 1px solid var(--neutural400);
}

@media only screen and (min-width: 768px) {
    .button {
        flex-grow: 0;
    }

    .cancel:hover,
    .cancel:focus {
        border: 1px solid var(--neutural900);
    }

    .confirm:focus {
        background-color: var(--indigo800);
    }

    .button:hover,
    .button:focus {
        box-shadow: 0px 0px 0px 6px var(--indigo300);
        text-decoration: underline;
        text-underline-offset: 2px;
        text-decoration-thickness: 1px;
        transition: all 200ms ease-out;
    }
}
