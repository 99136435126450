import {
    BotInfo,
    BrowserInfo,
    NodeInfo,
    ReactNativeInfo,
    SearchBotDeviceInfo,
    detect,
} from 'detect-browser'
import { Instructions } from './sections/Instructions'
import { NavigationButton } from '../NavigationButton'
import { BoldCaption } from '../typography/Typography'
import { Title } from './sections/Title'
import config from '../../config'

import css from './Checkbox.module.css'

const mobilePlatforms = ['iOS', 'Android OS', 'BlackBerry OS', 'Windows Mobile']

function isSupported(name: string): boolean {
    if (!name) {
        return false
    }

    return ['chrome', 'safari', 'edge', 'edge-chromium', 'firefox', 'opera'].indexOf(name) > -1
}

function getIcon(icon: string) {
    switch (icon) {
        case 'chrome':
            return '0px 0px'
        case 'safari':
            return 'calc(-32px - 24px) 0px'
        case 'edge':
        case 'edge-chromium':
            return 'calc(-32px*2 - 24px*2) 0px'
        case 'firefox':
            return 'calc(-32px*3 - 24px*3) 0px'
        case 'opera':
            return 'calc(-32px*4 - 24px*4) 0px'
        case 'ios':
            return 'calc(-32px*5 - 24px*5) 0px'
        case 'android':
            return 'calc(-32px*6 - 24px*6) 0px'
    }
}

function getBrowserName(
    browser: BrowserInfo | SearchBotDeviceInfo | BotInfo | NodeInfo | ReactNativeInfo
) {
    const name = browser.name
    if (/edge/s.exec(name)) {
        return 'edge'
    }
    return name
}

export function NoRecPermissions({ onSubmit, question }: Novoic.CheckboxQuestionProps) {
    const { id, question: body } = question
    const { title, browsers, mobile, checkboxes } = body

    const state = checkboxes.map((checkbox) => ({
        ...checkbox,
        value: checkbox.default,
        pristine: true,
    }))

    const browser = detect()
    const browserName = browser && isSupported(browser.name) ? getBrowserName(browser) : 'chrome'
    const isMobile = mobilePlatforms.indexOf(browser.os) > -1
    const isiOS = browser.os === 'iOS'

    let copy = browsers[browserName]

    if (isMobile) {
        copy = mobile[isiOS ? 'ios' : 'android']
    }

    return (
        <div className={css.container}>
            <Title>{title}</Title>

            <div className={css.browser_title}>
                <img
                    className={css.browser_icon}
                    style={{ objectPosition: getIcon(copy.icon) }}
                    src={config.s3.getStaticURL('browsers.svg')}
                />
                <BoldCaption>{copy.title}</BoldCaption>
            </div>
            <Instructions instructions={copy.instructions} />

            <div className={css.button_container}>
                <NavigationButton
                    role="confirm"
                    label={body.button}
                    onClick={async () => {
                        await onSubmit(id, {
                            checkboxes: state.reduce((acc, checkbox) => {
                                acc[checkbox.name] = checkbox.value
                                return acc
                            }, {} as Record<string, boolean>),
                        })
                    }}
                />
            </div>
        </div>
    )
}
