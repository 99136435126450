import axios, { AxiosResponse } from 'axios'

export function useSubmit<T>(): [
    (arg0: Novoic.Answer<T>) => Promise<[Novoic.Question<unknown>, number]>
] {
    async function onSubmit<T extends Record<string, any>>(
        payload: Novoic.Answer<T>
    ): Promise<[Novoic.Question<unknown>, number]> {
        const { data, status } = await axios.post<any, AxiosResponse<Novoic.Question<unknown>>>(
            `${process.env.API_HOST}`,
            {
                answer: payload.answer,
            }
        )

        return [data, status]
    }

    return [onSubmit]
}
