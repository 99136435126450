import { start } from '@novoic/storyteller-js'

async function bootstrap() {
    const response = await fetch(`${process.env.ASSIGN_HOST}${location.pathname}${location.search}`)
    const data = await response.json()
    return data
}

function run(assignmentId, language) {
    start(element, {
        assignmentId,
        language,
        token: localStorage.getItem('token'),
        onTimeout: async () => {
            console.log('timed out')
        },
        onComplete: async () => {
            window.location.href = 'https://www.youtube.com/watch?v=dQw4w9WgXcQ'
        },
        onExit() {
            console.log('🚪 Bye-bye!')
        },
    })
}

const element = document.getElementById('app')

if (!element) {
    throw Error('Container for application mush have an id "app".')
}

const params = new URLSearchParams(location.search)
const assignmentId = params.get('assignment_id')
const language = params.get('language')

if (!assignmentId) {
    bootstrap().then(({ token, assignment_id: assignmentId, language }) => {
        localStorage.setItem('token', token)
        location.href = `${location.origin}?assignment_id=${assignmentId}&language=${language}`
    })
} else {
    run(assignmentId, language)
}
