.container {
    height: 100%;
    display: flex;
    gap: 16px;
    flex-direction: column;
    flex-grow: 1;
}

.progress {
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 24px;
}

.error {
    color: #bd0100;
}
