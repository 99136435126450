import { useMemo, useEffect, useContext, useState } from 'react'
import { AudioStreamer } from '../AudioStreamer'
import { RTCContext } from '../RTCContext'
import { useTimer } from './useTimer'

interface Player {
    play: (audio: string) => Promise<MediaStream>
    track: {
        currentTime: number
        isEnded: boolean
    }
}

interface Recorder {
    start: () => Promise<string>
    stop: () => Promise<void>
    analyser: AnalyserNode
    currentTime: number
}

/**
 * Provides with WebRTC streaming capabilities boiled down to the simple
 * interface of "start" and "stop" functions
 */
export function useAudioStreamer(attempt: number): [Player, Recorder, boolean, unknown] {
    const [startTimer, stopTimer, timer] = useTimer()
    const [streamAnalyser, setStreamAnalyser] = useState<AnalyserNode>()
    const [isConnected, setIsConnected] = useState<boolean>(false)
    const [isEnded, setIsEnded] = useState<boolean>(false)
    const [error, setError] = useState<unknown>()
    const config = useContext(RTCContext)
    const streamer = useMemo<AudioStreamer>(
        () => new AudioStreamer(config),
        [config.iceServers?.length, attempt]
    )

    function onTrackStart() {
        startTimer()
    }

    function onTrackEnd() {
        setIsEnded(true)
        stopTimer()
    }

    function onConnected() {
        setIsConnected(true)
    }

    function onDisconnected() {
        setIsConnected(false)
    }

    function onError(e: unknown) {
        setError(e)
    }

    useEffect(() => {
        streamer.ee.addListener('error', onError)
        streamer.ee.addListener('track.start', onTrackStart)
        streamer.ee.addListener('track.end', onTrackEnd)
        streamer.ee.addListener('connected', onConnected)
        streamer.ee.addListener('disconnected', onDisconnected)

        return () => {
            streamer.ee.removeListener('error', onError)
            streamer.ee.removeListener('track.start', onTrackStart)
            streamer.ee.removeListener('track.end', onTrackEnd)
            streamer.ee.removeListener('connected', onConnected)
            streamer.ee.removeListener('disconnected', onDisconnected)
        }
    }, [streamer])

    useEffect(() => {
        if (config) {
            console.log('call connect as config has changed', config)
            streamer.connect()
        }
    }, [config.iceServers?.length, attempt])

    /**
     * Starts recording
     * @returns {Promise<string>} recording UUID
     */
    async function start(): Promise<string> {
        const recordingUUID = await streamer.start()
        const analyser = streamer.getStreamAnalyser()

        setStreamAnalyser(analyser)

        startTimer()
        return recordingUUID
    }

    /**
     * Stops the recording
     * @returns {Promise<void>}
     */
    async function stop(): Promise<void> {
        await streamer.stop()
        stopTimer()
    }

    async function play(audio: string): Promise<MediaStream> {
        setIsEnded(false)
        const stream = await streamer.play(audio)

        return stream
    }

    const player: Player = {
        play,
        track: {
            currentTime: timer,
            isEnded,
        },
    }

    const recorder: Recorder = {
        start,
        stop,
        analyser: streamAnalyser,
        currentTime: timer,
    }

    return [player, recorder, isConnected, error]
}
