interface ProgressProps {
    canvas: HTMLCanvasElement
    color?: string
    duration: number
    onEnd: () => void
}

export function useProgress({ canvas, color = '#4338ca', duration, onEnd }: ProgressProps) {
    if (!canvas) {
        return
    }

    const context = canvas.getContext('2d')
    const stroke = 2 * devicePixelRatio
    const radius = (canvas.width - 2 * stroke) / 2
    const circ = Math.PI * 2
    const quart = Math.PI / 2
    const x0 = canvas.width / 2
    const y0 = canvas.height / 2

    let progress = 0
    let lastTimestamp = 0

    context.strokeStyle = color
    context.lineWidth = stroke
    context.fillStyle = '#fff'

    function draw() {
        context.clearRect(0, 0, canvas.width, canvas.height)
        context.beginPath()
        context.arc(x0, y0, radius, -quart, (circ * progress) / duration - quart)
        context.stroke()
    }

    function animate() {
        const timestamp = performance.now()

        if (lastTimestamp) {
            progress += timestamp - lastTimestamp
        }

        draw()

        lastTimestamp = timestamp
        if (progress <= duration) {
            requestAnimationFrame(() => {
                animate()
            })
        } else {
            onEnd()
        }
    }

    return {
        start() {
            animate()
        },
    }
}
