import { useEffect, useMemo, useState } from 'react'
import { useProgress } from '../../hooks/useProgress'
import css from './ProgressButton.module.css'

interface ButtonProps {
    label: string
    duration: number
}

export function ProgressButton({ label, duration }: ButtonProps) {
    const [canvas, setCanvas] = useState<HTMLCanvasElement>()
    const controls = useMemo(
        () =>
            useProgress({
                canvas,
                duration,
                onEnd: () => {},
            }),
        [canvas]
    )

    function onRef(node: HTMLCanvasElement) {
        setCanvas(node)
    }

    useEffect(() => {
        if (canvas && controls) {
            controls.start()
        }
    }, [controls, canvas])

    return (
        <div className={css.container}>
            <canvas
                className={css.canvas}
                ref={onRef}
                width={130 * devicePixelRatio}
                height={130 * devicePixelRatio}
            />
            <button disabled className={css.button}>
                {label}
            </button>
        </div>
    )
}
