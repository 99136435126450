import css from './PlayerButton.module.css'

interface PlayerButtonProps {
    label: string
    isDisabled?: boolean
    onClick: () => void
}

export function PlayerButton(props: PlayerButtonProps) {
    return (
        <div className={css.container}>
            <div className={css.player_outside}>
                <button
                    disabled={props.isDisabled}
                    onClick={props.onClick}
                    className={`${css.button} ${css.player}`}
                >
                    <p className={css.button_text}>{props.label}</p>
                    <div className={`${css.overlay} ${css.player_overlay}`}>
                        <div className={css.player_overlay_icon} />
                    </div>
                </button>
            </div>
        </div>
    )
}
