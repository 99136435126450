import axios from 'axios'
import { useContext } from 'react'
import { MiddlewareContext } from '../MiddlewareContext'

export function useAxiosMiddleware() {
    const { question, assignmentId } = useContext(MiddlewareContext)

    axios.interceptors.request.clear()

    axios.interceptors.request.use(
        (config) => {
            if (config.method !== 'post') {
                return config
            }

            if (typeof config.data === 'string') {
                return config
            }

            if (!config.data) {
                config.data = {}
            }
            config.data.question_id = question?.id
            config.data.assignment_id = assignmentId
            return config
        },
        (error: any) => {
            return Promise.reject(error)
        }
    )
}
