import css from './ProcessingButton.module.css'
import config from '../../config'

export function ProcessingButton() {
    return (
        <div className={css.container}>
            <div className={css.processing_outside}>
                <button disabled className={`${css.button} ${css.processing}`}>
                    <img className={css.loader} src={config.s3.getStaticURL('loader.svg')} />
                </button>
            </div>
        </div>
    )
}
