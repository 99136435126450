import { Instructions } from './sections/Instructions'

import css from './Checkbox.module.css'
import { NavigationButton } from '../NavigationButton'
import { RegularCaption } from '../typography/Typography'
import { Title } from './sections/Title'
import { List } from './sections/List'

export function NoSound({ onSubmit, question }: Novoic.CheckboxQuestionProps) {
    const { id, question: body } = question
    const { title, instructions, list, subtext, checkboxes } = body

    const state = checkboxes.map((checkbox) => ({
        ...checkbox,
        value: checkbox.default,
        pristine: true,
    }))

    return (
        <div className={css.container}>
            <Title>{title}</Title>
            {subtext && <RegularCaption>{subtext}</RegularCaption>}
            {list && <List list={list} />}
            {instructions && <Instructions instructions={instructions} />}

            <div className={css.button_container}>
                <NavigationButton
                    role="confirm"
                    label={body.button}
                    onClick={() =>
                        onSubmit(id, {
                            checkboxes: state.reduce((acc, checkbox) => {
                                acc[checkbox.name] = checkbox.value
                                return acc
                            }, {} as Record<string, boolean>),
                        })
                    }
                />
            </div>
        </div>
    )
}
