import { useEffect, useState } from 'react'

export function useTimer(): [() => void, () => void, number] {
    const [timer, updateTimer] = useState<number>(0)
    const [interval, setInterval] = useState<number>()
    const [isActive, setIsActive] = useState<boolean>(false)

    function start() {
        updateTimer(0)
        setIsActive(true)
    }

    function stop() {
        setIsActive(false)
    }

    useEffect(() => {
        if (!isActive) {
            window.clearInterval(interval)
            return
        }

        setInterval(
            window.setInterval(() => {
                updateTimer((t) => t + 1)
            }, 1000)
        )

        return () => window.clearInterval(interval)
    }, [isActive])

    return [start, stop, timer]
}
