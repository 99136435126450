import ReactMarkdown from 'react-markdown'
import { BoldCaption } from '../typography/Typography'
import css from './Modal.module.css'
import { NavigationButton } from '../NavigationButton'
import { Icon } from '../Icon'

interface PromptModalProps {
    caption: string
    text?: string
    confirmText?: string
    cancelText?: string
    onCancel?: () => Promise<void>
    onConfirm?: () => Promise<void>
}

export function GenericModalContent(props: PromptModalProps) {
    return (
        <div className={css.generic_error_content}>
            <div className={css.generic_header}>
                <Icon name={'satisfied2'} />
                <BoldCaption className={css.modal_caption}>{props.caption}</BoldCaption>
            </div>

            <div className={css.markdown}>
                <ReactMarkdown>{props.text}</ReactMarkdown>
            </div>

            <div className={css.button_container}>
                <NavigationButton role="cancel" label={props.cancelText} onClick={props.onCancel} />
                <NavigationButton
                    role="confirm"
                    label={props.confirmText}
                    onClick={props.onConfirm}
                />
            </div>
        </div>
    )
}
