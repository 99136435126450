import { EventEmitter } from 'eventemitter3'
import { Deferred } from './Deferred'
import { MediaDevice } from './MediaDevice'
import { WebRTCStreamer } from './WebRTCStreamer'

const AudioContext = window.AudioContext || (window as any).webkitAudioContext

export class AudioStreamer {
    /**
     * Reference to the local MediaStream
     */
    #stream: MediaStream

    /**
     * Reference/tracker for the remote MediaStream
     */
    #isTrackReady = new Deferred<MediaStream>()

    /**
     * Streaming indicator. Prevents multiple requests for streaming
     */
    #isSteraming: Deferred<boolean>

    /**
     * Reference to the MediaRecorder
     */
    #streamer: WebRTCStreamer

    /**
     * Object that wraps navigator.getUserMedia to reduce boilerplate
     */
    #mediaDevice: MediaDevice

    /**
     * RCTConfiguration for the WebRTCStreamer
     */
    #rtcConfig: RTCConfiguration

    /**
     * Promise to indicate the state of preparing
     */
    #isPreparing = new Deferred<void>()

    /**
     * Event Emitter to communicate back the "track" event
     */
    ee = new EventEmitter()

    constructor(config: RTCConfiguration) {
        this.#mediaDevice = new MediaDevice()
        this.#rtcConfig = config
    }

    private async createMediaStream(): Promise<MediaStream> {
        if (!this.#mediaDevice.isSupportingAudioRecording()) {
            throw Error('mediaDevices or getUserMedia method is not supported.')
        }

        try {
            const stream = await this.#mediaDevice.getStream()
            return stream.clone()
        } catch (e: unknown) {
            throw this.#mediaDevice.processMediaDeviceError(e as Error)
        }
    }

    getStreamAnalyser(): AnalyserNode {
        if (!this.#stream) {
            return null
        }

        const audioCtx = new AudioContext()
        const audioSource = audioCtx.createMediaStreamSource(this.#stream)
        const analyser = audioCtx.createAnalyser()

        audioSource.connect(analyser)

        analyser.fftSize = 32

        return analyser
    }

    async connect(): Promise<void> {
        try {
            this.#stream = await this.createMediaStream()
            this.#streamer = new WebRTCStreamer(this.#stream, this.#rtcConfig)

            this.#streamer.ee
                .addListener('connected', () => {
                    this.ee.emit('connected')
                })
                .addListener('disconnected', () => {
                    this.ee.emit('disconnected')
                })
                .addListener('track', (stream: MediaStream) => {
                    this.#isTrackReady.resolve(stream)
                    this.#isSteraming = undefined
                })
                .addListener('track.start', () => {
                    this.ee.emit('track.start')
                })
                .addListener('track.end', () => {
                    this.ee.emit('track.end')
                })

            this.#isPreparing.resolve()
        } catch (e: unknown) {
            this.#isPreparing.reject(e)
            this.ee.emit('error', e)
        }
    }

    async play(audio: string): Promise<MediaStream> {
        if (!this.#isSteraming) {
            await this.#isPreparing.promise
            this.#isSteraming = new Deferred()
            this.#streamer.play(audio)
            this.#isSteraming.resolve(true)
        }
        return await this.#isTrackReady.promise
    }

    /**
     * Starts audio recording
     * @returns {Promise<string | null>} Resolves with the recording UUID if the audio streaming process has been started, otherwise null
     */
    async start(): Promise<string> {
        await this.#isPreparing.promise

        try {
            const recording = await this.#streamer.start()
            console.debug('streaming has been started')
            return recording
        } catch (e: unknown) {
            console.error('Failed to establish a streaming connection')
        }
    }

    /**
     * Stops the recording
     * @returns void
     */
    async stop(): Promise<void> {
        if (!this.#streamer) {
            throw Error('There are no active recording at the time')
        }

        await this.#streamer.stop()

        console.debug('streaming has stopped')

        /**
         * Stop all tracks within the stream to remove the recordinig indication
         */
        this.#stream.getTracks().forEach((track: MediaStreamTrack) => track.stop())

        /**
         * Notify that the connection is closed or is about to be closed
         */
        this.#streamer.ee.emit('disconnected')

        /**
         * Reset AudioStreamer state
         */
        this.#streamer = undefined
        this.#stream = undefined
    }
}
