import { useContext, useEffect, useState } from 'react'
import css from './Progress.module.css'
import { MiddlewareContext } from '../../MiddlewareContext'

function Progress(props: React.HTMLAttributes<HTMLDivElement>) {
    let items = []

    const { question } = useContext(MiddlewareContext)

    if (question) {
        const { total, index } = question

        for (let i = 0; i <= total; i++) {
            items.push(
                <div
                    key={`item-${i}`}
                    className={`${css.item} ${index === i ? css.active : ''}`}
                ></div>
            )
        }
    } else {
        return null
    }

    return <div className={`${props.className} ${css.container}`}>{items}</div>
}

export function MobileProgress() {
    const [width, setWidth] = useState<number>(screen.width)
    useEffect(() => {
        function handleResize() {
            setWidth(screen.width)
        }

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    if (width >= 768) {
        return null
    }

    return <Progress className={css.mobile} />
}

export function DesktopProgress() {
    const [width, setWidth] = useState<number>(screen.width)
    useEffect(() => {
        function handleResize() {
            setWidth(screen.width)
        }

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    if (width < 768) {
        return null
    }

    return <Progress className={css.desktop} />
}
