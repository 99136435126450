import config from '../config'
import { useFonts } from './useFonts'
import { usePreload } from './usePreload'

export function useAssets(): boolean {
    const areFontsLoaded = useFonts()
    const isContentLoaded = usePreload([
        { url: config.s3.getStaticURL('icons.svg'), type: 'image' },
        { url: config.s3.getStaticURL('close.svg'), type: 'image' },
        { url: config.s3.getStaticURL('success.svg'), type: 'image' },
        { url: config.s3.getStaticURL('loader.svg'), type: 'image' },
        { url: config.s3.getStaticURL('play.svg'), type: 'image' },
        { url: config.s3.getStaticURL('stop.svg'), type: 'image' },
        { url: config.s3.getStaticURL('rec.svg'), type: 'image' },
        { url: config.s3.getStaticURL('checkmark.svg'), type: 'image' },
        { url: config.s3.getStaticURL('logo.svg'), type: 'image' },
        { url: config.s3.getStaticURL('bluetooth.svg'), type: 'image' },
        { url: config.s3.getStaticURL('browsers.svg'), type: 'image' },
    ])

    return areFontsLoaded && isContentLoaded
}
