import axios, { AxiosError } from 'axios'
import { useEffect, useState } from 'react'

import { RTCContext } from '../RTCContext'
import config from '../config'
import { useQuestion } from '../hooks/useQuestion'
import { useSubmit } from '../hooks/useSubmit'
import { Question } from './Question'

import { DataContext } from '../DataContext'
import { MiddlewareContext } from '../MiddlewareContext'
import { useAssets } from '../hooks/useAssets'
import { useICEServers } from '../hooks/useICEServers'
import { useSentry } from '../hooks/useSentry'
import { useSessionStorage } from '../hooks/useSessionStorage'
import css from './App.module.css'
import { GlobalModalContext } from './GlobalModalContext'
import { ProcessingButton } from './base/ProcessingButton'

interface AppProps {
    assignmentId: string
    onComplete?: () => void
    onTimeout?: () => Promise<void>
    onExit?: () => void
}

function isComplete(status: number): boolean {
    return status === 423 || status === 204
}

function isAssignmentExpired(status: number): boolean {
    return status === 408
}

function isExit(status: number): boolean {
    return status === 410
}

export function App({
    assignmentId,
    onComplete = () => Promise.resolve(),
    onTimeout = () => Promise.resolve(),
    onExit = () => Promise.resolve(),
}: AppProps) {
    const iceServers = useICEServers()
    const [question, setQuestion] = useState<Novoic.Question<unknown> | undefined>()
    const [isExpired, setIsExpired] = useState<boolean>(false)
    const areAssetsLoaded = useAssets()
    const [errors, setErrors] = useState<Record<string, Record<string, unknown>>>({})
    const [onSubmit] = useSubmit()
    const [initialQuestion, status] = useQuestion(assignmentId)
    const [set, get] = useSessionStorage()

    const userAgent = navigator.userAgent
    const isFacebookBrowser = /Android/i.test(userAgent) && /FBAV|FBAN|FB_IAB/i.test(userAgent);

    useEffect(() => {
        useSentry()
    }, [])

    useEffect(() => {
        if (question) {
            set<Novoic.Question<unknown>>('lastQuestion', question)
        }
    }, [question])

    useEffect(() => {
        if (isFacebookBrowser) {
            setQuestion({
                type: 'checkbox',
                id: 'unsupported_browser',
                question: {
                    'category': 'unsupported-browser',
                }
            } as unknown as Novoic.AnyQuestion)
            return
        }
        if (isAssignmentExpired(status)) {
            setIsExpired(true)
        } else if (isExit(status)) {
            onExit?.()
        } else if (isComplete(status)) {
            onComplete?.()
        } else {
            setQuestion(initialQuestion)
        }
    }, [initialQuestion, status])

    /**
     * If the question is not yet fetched and not expired
     */
    if (!question || !areAssetsLoaded) {
        if (!isExpired) {
            return <ProcessingButton />
        }
    }

    /**
     * Universal submit handler
     * @param {string} questionID ID of the question
     * @param answer Value to submit as answer
     * @returns {Promise<Novoic.ValidationError[] | void>}
     */
    async function submit<T extends Record<string, any>>(
        questionID: string,
        answer: T
    ): Promise<Record<string, any> | void> {
        try {
            const [newQuestion, status] = await onSubmit({
                answer,
            })

            sessionStorage.clear()

            if (isComplete(status)) {
                onComplete?.()

                if ((question.question as any).redirectUrl) {
                    location.href = (question.question as any).redirectUrl
                }

                return
            }

            setQuestion(newQuestion)
        } catch (e: unknown) {
            const response = (e as AxiosError<Novoic.SubmitResponse>).response
            if (isAssignmentExpired(response.status)) {
                setIsExpired(true)
                return
            }

            const { data } = response.data

            if (response) {
                setErrors({ [questionID]: data })
            }

            return data
        }
    }

    async function exit(): Promise<void> {
        await axios.delete(`${process.env.API_HOST}`)
        onExit?.()
    }

    return (
        <RTCContext.Provider value={{ iceServers }}>
            <div className={css.container}>
                <div className={css.content}>
                    <DataContext.Provider value={{ assignmentId, setIsExpired }}>
                        <MiddlewareContext.Provider
                            value={{
                                assignmentId,
                                question,
                                onTimeout,
                                isExpired,
                                errors: errors[question?.id],
                                submit,
                                exit,
                            }}
                        >
                            <GlobalModalContext>
                                <Question />
                            </GlobalModalContext>
                        </MiddlewareContext.Provider>
                    </DataContext.Provider>
                </div>
                <img
                    width={207}
                    height={21}
                    className={css.logo}
                    src={config.s3.getStaticURL('logo.svg')}
                    alt="Novoic Storyteller"
                />
            </div>
        </RTCContext.Provider>
    )
}
