import { useEffect, useState } from 'react'
import { Deferred } from '../Deferred'

type PreloadObject = { url: string; type: string }

export function usePreload(content: PreloadObject[] = []): boolean {
    const [complete, setComplete] = useState<boolean>(false)

    useEffect(() => {
        const loadings = content.map(({ url, type }: PreloadObject) => {
            const def = new Deferred<boolean>()

            const link = document.createElement('link')
            link.rel = 'preload'
            link.as = type
            link.href = url

            link.onload = function () {
                def.resolve(true)
            }

            document.head.appendChild(link)

            return def.promise
        })

        Promise.all(loadings).then(() => {
            setComplete(true)
        })
    }, [])

    return complete
}
