import axios, { AxiosError } from 'axios'
import { useContext } from 'react'
import { DataContext } from '../DataContext'

function isAssignmentExpired(status: number): boolean {
    return status === 408
}

export function useActivityPing() {
    const { setIsExpired } = useContext(DataContext)

    return function updateActivityTimer(): Promise<void> {
        try {
            return axios.post(`${process.env.API_HOST}/time`)
        } catch (e: unknown) {
            const status = (e as AxiosError).response.status
            if (isAssignmentExpired(status)) {
                setIsExpired(true)
            }
        }
    }
}
