.modal {
    position: relative;
    display: flex;
    gap: 16px;
    padding: 32px;
    border-radius: 16px;
    flex-direction: column;
    align-self: center;
    background-color: var(--neutural000);
    box-shadow: 0px 0px 1px rgba(49, 46, 129, 0.32), 0px 6px 8px -2px rgba(49, 46, 129, 0.02),
        0px 16px 64px -8px rgba(49, 46, 129, 0.18);
    animation: modalIn 300ms ease-out;
}

.modal_exit {
    animation: modalOut 300ms ease-out;
}

.modal_caption {
    display: block;
    width: calc(100% - 32px);
}

.markdown strong,
.markdown b {
    font-weight: 500;
}

.prompt_content {
    display: flex;
    flex-direction: column;
    width: 240px;
    gap: 16px;
}

.generic_header {
    display: flex;
    gap: 8px;
    align-items: center;
}

.generic_error_content {
    display: flex;
    flex-direction: column;
    width: 278px;
    gap: 16px;
}

.backdrop {
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.8);
    animation: fadeIn 300ms ease-out;
}

.backdrop_exit {
    animation: fadeOut 300ms ease-out;
}

.closeIcon {
    position: absolute;
    cursor: pointer;
    right: 16px;
    top: 16px;
    width: 32px;
    height: 32px;
}

.button_container {
    margin-top: 8px;
    display: flex;
    flex-wrap: wrap;
    flex-flow: wrap-reverse;
    align-items: flex-end;
    justify-content: center;
    flex-grow: 1;
    gap: 8px;
}

.button_container button {
    flex-grow: 1;
}

@media only screen and (min-width: 768px) {
    .button_container button {
        flex-grow: 1;
    }

    .button_container {
        flex-wrap: nowrap;
    }
}
