.transition_enter {
    opacity: 0;
    transform: translateY(-24px);
}

.transition_enter_active {
    opacity: 1;
    transform: translateY(0px);
    transition: all 300ms;
}

.transition_exit {
    opacity: 1;
    transform: translateY(0px);
}

.transition_exit_active {
    opacity: 0;
    transform: translateY(-24px);
    transition: all 300ms;
}
