.container {
    width: 128px;
    height: 128px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.button {
    width: 112px;
    height: 112px;
    border-radius: 50%;
    background-color: var(--indigo50);
    color: var(--indigo700);
    font-size: 18px;
    line-height: 22px;
    font-weight: 400;
    border: none;
    position: relative;
}

.canvas {
    width: 130px;
    height: 130px;
    position: absolute;
}
