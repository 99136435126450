enum UserMediaErrors {
    ABORT = 'AbortError',
    NOT_ALLOWED = 'NotAllowedError',
    NOT_FOUND = 'NotFoundError',
    NOT_READABLE = 'NotReadableError',
    OVERCONSTRAINED = 'OverconstrainedError',
    SECURITY = 'SecurityError',
    TYPE = 'TypeError',
}

export class MediaDevice {
    /**
     * Helper function to detect whether user's device is capable of recording audio streams
     * @returns boolean Returns a boolean that indicates if user's device is capable of recording audio streams
     */
    isSupportingAudioRecording(): boolean {
        return Boolean(navigator.mediaDevices && navigator.mediaDevices.getUserMedia)
    }

    /**
     * Helper function to process Media Device Stream errors
     * @param e Error Media Device Stream error to process
     * @returns void
     */
    processMediaDeviceError(e: Error) {
        switch (e.name) {
            case UserMediaErrors.ABORT:
                return 'Aborted by the user.'

            case UserMediaErrors.NOT_ALLOWED:
                return `Audio input device permissions has been denied: ${e.message}`

            case UserMediaErrors.NOT_FOUND:
                return `Audio input sources not found: ${e.message}`

            case UserMediaErrors.NOT_READABLE:
                return `Audio media stream is not readable: ${e.message}`

            case UserMediaErrors.OVERCONSTRAINED:
                return `Audio input device is overconstrained: ${e.message}`

            case UserMediaErrors.SECURITY:
                return `Audio input device security issue: ${e.message}`

            case UserMediaErrors.TYPE:
                return `List of audio device constrains is set to empty or all constrains are set to false: ${e.message}`

            default:
                return `An unknown error occured during the initialization process of the media stream: ${e.message}`
        }
    }

    async getStream(): Promise<MediaStream> {
        const devices = await navigator.mediaDevices.enumerateDevices()
        const device = devices.find((d) => d.kind === 'audioinput' && d.label.includes('Default'))
        return navigator.mediaDevices.getUserMedia({
            audio: {
                deviceId: device?.deviceId,
                channelCount: 2,
                noiseSuppression: false,
                autoGainControl: true,
                echoCancellation: false,
            },
        })
    }
}
