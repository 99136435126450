import ReactMarkdown from 'react-markdown'
import { BoldCaption } from '../typography/Typography'
import css from './Modal.module.css'
import { NavigationButton } from '../NavigationButton'

interface PromptModalProps {
    caption: string
    text?: string
    cancelText?: string
    confirmText?: string
    onCancel?: () => Promise<unknown>
    onConfirm?: () => Promise<unknown>
}

PromptModalContent.defaultProps = {
    confirmText: 'buttons.yes',
    cancelText: 'buttons.no',
    onCancel: () => Promise.resolve(),
    onConfirm: () => Promise.resolve(),
}

export function PromptModalContent(props: PromptModalProps) {
    return (
        <div className={css.prompt_content}>
            <BoldCaption className={css.modal_caption}>{props.caption}</BoldCaption>

            <div className={css.markdown}>
                <ReactMarkdown>{props.text}</ReactMarkdown>
            </div>

            <div className={css.button_container}>
                <NavigationButton role="cancel" label={props.cancelText} onClick={props.onCancel} />
                <NavigationButton
                    role="confirm"
                    label={props.confirmText}
                    onClick={props.onConfirm}
                />
            </div>
        </div>
    )
}
