import { PropsWithChildren } from 'react'

import css from './Title.module.css'

interface TitleProps extends PropsWithChildren {
    flexible?: boolean
    accent?: boolean
}

export function Title({ accent, children, flexible }: TitleProps) {
    return (
        <div key={Date.now()}>
            <h2 className={`${flexible && css.flexible} ${accent && css.accent}`}>{children}</h2>
        </div>
    )
}
