import { createContext } from 'react'

export interface ModalConfig {
    render: (config: ModalConfig) => JSX.Element
    canClose?: boolean
    onCancel?: () => Promise<unknown>
    onConfirm?: () => Promise<unknown>
    onClose?: () => Promise<unknown>
}

export interface ModalAPI {
    open: (config: ModalConfig) => void
    close: () => void
}

export const ModalContext = createContext<ModalAPI>(undefined)
