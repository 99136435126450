export interface Config {
    webRTC: {
        urls: Record<'sessions' | 'recordings' | 'ice', string>
    }
    s3: {
        buckets: Record<'static', string>
        getStaticURL(file: string): string
    }
}

function getStaticURL(file: string) {
    return config.s3.buckets.static + '/' + file
}

const config: Config = {
    webRTC: {
        urls: {
            ice: `${process.env.WEBRTC_HOST}/iceServers`,
            sessions: `${process.env.WEBRTC_HOST}/sessions`,
            recordings: `${process.env.WEBRTC_HOST}/recordings`,
        },
    },
    s3: {
        buckets: {
            static: 'https://novoic-static.s3.us-west-2.amazonaws.com',
        },
        getStaticURL,
    },
}

export default config
