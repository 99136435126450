.list {
    list-style-type: none;
    line-height: 2em;
    padding: 0;
    margin: 4px 0 0;
}

.list li {
    display: flex;
    align-items: center;
    padding-bottom: 16px;
    line-height: 24px;
    color: var(--neutural900);
}

.list li:last-of-type {
    padding-bottom: 0px;
}

.list_icon {
    object-fit: none;
    object-position: 0px 0px;
    width: 32px;
    height: 32px;
}

.text {
    padding-left: 16px;
}
