@keyframes animate {
    0% {
        transform: translateY(16px);
        opacity: 0;
    }

    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}

.card {
    background: var(--neutural000);
    position: relative;
}

.animate {
    animation: animate 300ms ease-out;
}

.low {
    box-shadow: 0px 0px 1px rgba(49, 46, 129, 0.32), 0px 6px 8px -2px rgba(49, 46, 129, 0.02),
        0px 16px 64px -8px rgba(49, 46, 129, 0.18);
}

.medium {
    box-shadow: 0px 0px 1px rgba(49, 46, 129, 0.32), 0px 2px 4px -1px rgba(49, 46, 129, 0.08),
        0px 8px 20px -4px rgba(49, 46, 129, 0.12);
}

.high {
    box-shadow: 0px 0px 1px rgba(49, 46, 129, 0.32), 0px 1px 2px rgba(49, 46, 129, 0.12),
        0px 2px 4px rgba(49, 46, 129, 0.08);
}
