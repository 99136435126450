import type { PropsWithChildren, ComponentPropsWithoutRef } from 'react'
import css from './Typography.module.css'

export function RegularCaption(props: PropsWithChildren) {
    return <span className={css.caption}>{props.children}</span>
}

interface CaptionProps extends ComponentPropsWithoutRef<'div'> {}

interface CaptionLinkProps extends CaptionProps {
    href: string
}

export function RegularCaptionLink(props: CaptionLinkProps) {
    return (
        <a href={props.href} className={`${css.link} ${props.className}`} download target="_blank">
            <RegularCaption>{props.children}</RegularCaption>
        </a>
    )
}

export function BoldCaption(props: CaptionProps) {
    return <span className={`${css.caption} ${css.bold} ${props.className}`}>{props.children}</span>
}
