import axios, { AxiosError, AxiosResponse } from 'axios'
import { useEffect, useState } from 'react'

async function fetchData(): Promise<[Novoic.Question<unknown>, number]> {
    try {
        const { data, status } = await axios.get<any, AxiosResponse<Novoic.Question<unknown>>>(
            `${process.env.API_HOST}`
        )
        return [data, status]
    } catch (e: unknown) {
        return [undefined, (e as AxiosError).response.status]
    }
}

export function useQuestion(assignmentId: string): [Novoic.Question<unknown>, number, AxiosError] {
    const [question, setQuestion] = useState<Novoic.Question<unknown>>()
    const [status, setStatus] = useState<number>()
    const [error, setError] = useState<AxiosError>()

    useEffect(() => {
        fetchData()
            .then(([question, status]) => {
                setStatus(status)
                setQuestion(question)
            })
            .catch(setError)
    }, [assignmentId])

    return [question, status, error]
}
