.caption {
    font-weight: 300;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.017em;
    padding: 0;
}

.link {
    color: var(--indigo700);
    text-underline-offset: 3px;
}

.bold {
    font-weight: 500;
}
