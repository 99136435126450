import { MediaDevice } from '../../MediaDevice'
import { Instructions } from './sections/Instructions'
import { NavigationButton } from '../NavigationButton'

import css from './Checkbox.module.css'
import { Title } from './sections/Title'
import { RegularCaption } from '../typography/Typography'
import { MobileProgress, DesktopProgress } from '../Progress/Progress'
import { useTranslation } from 'react-i18next'

export function RecPermissionQuestion({ onSubmit, question }: Novoic.CheckboxQuestionProps) {
    const { id, question: body } = question
    const { title, instructions, subtext } = body as Novoic.SoundCheckQuestionBody

    const { t } = useTranslation()

    async function onClick() {
        const mediaDevice = new MediaDevice()
        if (!mediaDevice.isSupportingAudioRecording()) {
            throw Error('Sorry, your device does not support audio recording')
        }

        try {
            const stream = await mediaDevice.getStream()

            onSubmit(id, {
                checkboxes: {
                    permission: true,
                },
            })

            stream.getTracks().forEach((track) => {
                if (track.readyState == 'live') {
                    track.stop()
                }
            })
        } catch (e) {
            onSubmit(id, {
                checkboxes: {
                    permission: false,
                },
            })
        }
    }

    return (
        <div className={css.container}>
            <MobileProgress />
            <Title>{title}</Title>
            {subtext && <RegularCaption>{subtext}</RegularCaption>}
            {instructions && <Instructions instructions={instructions} />}

            <div className={css.button_container}>
                <DesktopProgress />
                <NavigationButton role="confirm" label={t('buttons.agree')} onClick={onClick} />
            </div>
        </div>
    )
}
