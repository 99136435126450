import { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useActivityPing } from '../../hooks/useActivityPing'
import { useAudioStreamer } from '../../hooks/useAudioStreamer'
import { ModalContext } from '../../ModalContext'
import { PlayerButton } from '../base/PlayerButton'
import { ProcessingButton } from '../base/ProcessingButton'
import { ProgressButton } from '../base/ProgressButton'
import { RecordingButton } from '../base/RecordingButton'

import css from './StreamingRecorder.module.css'
import { PromptModalContent } from '../questions/PromptModalContent'
import { GenericModalContent } from '../questions/GenericModalContent'
import { MiddlewareContext } from '../../MiddlewareContext'

type Titles = Record<
    'loading' | 'processing' | 'play' | 'playing' | 'record' | 'recording',
    string | undefined
>

interface StreamingRecorderProps extends React.HTMLAttributes<HTMLDivElement> {
    titles?: Partial<Titles>
    attempt: number
    audio: Novoic.Audio | Novoic.Audio[]
    recordingStartMode: Novoic.RecordingMode
    recordingStopMode: Novoic.RecordingMode
    maxRecordingTime: number
    onStateChange: (state: StreamingState) => void
    onStreamingEnded: (recordingUUID: string) => void
}

export enum StreamingState {
    'IDLE',
    'LOADING',
    'PLAYING',
    'STREAMING',
    'PROCESSING',
}

enum RecordingMode {
    AUTO = 'auto',
    MANUAL = 'manual',
}

export function StreamingRecorder(props: StreamingRecorderProps) {
    const {
        attempt,
        audio,
        titles: givenTitles,
        recordingStartMode,
        recordingStopMode,
        maxRecordingTime,
        onStreamingEnded,
        onStateChange,
    } = props
    const [state, setState] = useState<StreamingState>(StreamingState.LOADING)
    const { exit } = useContext(MiddlewareContext)
    const [player, recorder, isConnected, error] = useAudioStreamer(attempt)
    const [recordingUUID, setRecordingUUID] = useState<string>()
    const ping = useActivityPing()
    const modal = useContext(ModalContext)
    const video = useRef(document.createElement('video'))
    const { t } = useTranslation()

    const DEFAULT_TITLES = {
        loading: t('streaming.loading'),
        play: t('streaming.play'),
        playing: t('streaming.playing'),
        record: t('streaming.record'),
        recording: t('streaming.recordingManual'),
    }

    let file: string
    let duration: number

    if (Array.isArray(audio)) {
        file = audio[attempt].file
        duration = audio[attempt].duration
    } else {
        file = audio.file
        duration = audio.duration
    }

    const isIdle = state === StreamingState.IDLE
    const isLoading = state === StreamingState.LOADING
    const isPlaying = state === StreamingState.PLAYING
    const isStreaming = state === StreamingState.STREAMING
    const isProcessing = state === StreamingState.PROCESSING

    const autoStart = recordingStartMode === RecordingMode.AUTO

    const titles = Object.assign({}, DEFAULT_TITLES, givenTitles)

    useEffect(() => {
        video.current.setAttribute('title', `${Date.now()}`)
        video.current.setAttribute('alt', 'Question')
        video.current.setAttribute('playsinline', 'true')
        video.current.style.position = 'fixed'
        video.current.style.top = '0px'
        video.current.width = 1
        video.current.height = 1

        document.body.appendChild(video.current)
    }, [])

    useEffect(() => {
        if (maxRecordingTime - recorder.currentTime === 0) {
            onRecordingEnd()
        }
    }, [recorder.currentTime])

    /**
     * Reset the state if there is a new attempt
     */
    useEffect(() => {
        setState(StreamingState.LOADING)
    }, [attempt])

    /**
     * When the state changes, propagate the updated state to the parent
     */
    useEffect(() => {
        onStateChange(state)
    }, [state])

    /**
     * Start recording when the track is ended
     * and recording start mode is set to auto
     */
    useEffect(() => {
        if (player.track.currentTime > 0) {
            ;(async () => {
                if (player.track.isEnded && autoStart) {
                    try {
                        const recordingUUID = await recorder.start()
                        setRecordingUUID(recordingUUID)
                        setState(StreamingState.STREAMING)
                    } catch (e) {
                        console.error(e)
                    }
                }
            })()
        }
    }, [player.track.isEnded])

    useEffect(() => {
        console.log('is connected: ', isConnected)
        if (isConnected) {
            setState(StreamingState.IDLE)
        }
    }, [isConnected])

    useEffect(() => {
        if (error) {
            modal.open({
                render: ({ onConfirm, onCancel }) => (
                    <GenericModalContent
                        caption={t('modals.genericError.caption')}
                        text={t('modals.genericError.text')}
                        confirmText={t('modals.genericError.buttons.confirm')}
                        cancelText={t('modals.genericError.buttons.cancel')}
                        onCancel={async () => {
                            await exit()
                            await onCancel()
                        }}
                        onConfirm={async () => {
                            window.location.reload()
                            await onConfirm()
                        }}
                    />
                ),
                canClose: false,
            })
        }
    }, [error])

    async function onRecordingEnd() {
        setState(StreamingState.PROCESSING)
        await recorder.stop()
        onStreamingEnded(recordingUUID)
    }

    async function playStream() {
        setState(StreamingState.PLAYING)

        const stream = await player.play(file)
        video.current.srcObject = stream
        await video.current.play()
        await ping()
    }

    let button: JSX.Element

    if (isIdle) {
        button = <PlayerButton label={t('streaming.play')} onClick={playStream} />
    }

    if (isPlaying) {
        button = <ProgressButton duration={duration} label={titles.playing} />
    }

    if (isLoading || isProcessing) {
        button = <ProcessingButton />
    }

    if (isStreaming) {
        button = (
            <RecordingButton
                analyser={recorder.analyser}
                maxRecordingTime={maxRecordingTime}
                isDisabled={recordingStopMode === RecordingMode.AUTO}
                onClick={async () => {
                    modal.open({
                        render: ({ onConfirm, onCancel }) => (
                            <PromptModalContent
                                caption={t('modals.completeStreaming.caption')}
                                onConfirm={async () => {
                                    onConfirm()
                                    await onRecordingEnd()
                                }}
                                onCancel={onCancel}
                            />
                        ),
                    })
                }}
                label={titles.recording}
            />
        )
    }

    return (
        <div style={props.style} className={css.container}>
            {button}
        </div>
    )
}
