import { ChoiceQuestion } from './Choice'
import { CustomerSatisfactionQuestion } from './CustomerSatisfactionQuestion'

export function ChoiceQuestionFactory(props: Novoic.ChoiceQuestionProps) {
    const { question } = props.question
    switch (question.category) {
        case 'choice':
            return <ChoiceQuestion {...props} />
        case 'csat':
            return <CustomerSatisfactionQuestion {...props} />
    }
}
