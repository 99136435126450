import { useState, useEffect, useMemo } from 'react'
import css from './AudioPlayer.module.css'

import { PlayerButton } from '../base/PlayerButton'
import { ProgressButton } from '../base/ProgressButton'
import { useActivityPing } from '../../hooks/useActivityPing'
import { ProcessingButton } from '../base/ProcessingButton'

interface AudioPlayerProps extends React.HTMLAttributes<HTMLDivElement> {
    audio: {
        file: string
        duration: number
    }
    titles: Record<'start' | 'stop', string>
    isDisabled: boolean
    onStarted?: () => void
    onEnded?: () => void
}

export function AudioPlayer(props: AudioPlayerProps) {
    const [isReady, setIsReady] = useState<boolean>(false)
    const [isPlaying, setIsPlaying] = useState<boolean>(false)
    const ping = useActivityPing()

    const audio = new Audio()
    audio.src = props.audio.file

    audio.addEventListener('loadedmetadata', () => {
        setIsReady(true)
    })

    async function onPlay() {
        await audio.play()

        audio.addEventListener('ended', () => {
            props.onEnded?.()
            setIsPlaying(false)
        })

        setIsPlaying(true)
        props.onStarted?.()

        await ping()
    }

    let button

    if (!isReady) {
        button = <ProcessingButton />
    }

    if (isReady && !isPlaying) {
        button = (
            <PlayerButton
                isDisabled={props.isDisabled}
                onClick={onPlay}
                label={props.titles.start}
            />
        )
    }

    if (isReady && isPlaying) {
        button = <ProgressButton duration={props.audio.duration} label={props.titles.stop} />
    }

    return (
        <div style={props.style} className={css.container}>
            {button}
        </div>
    )
}
