.container {
    display: flex;
    gap: 8px;
}

.item {
    width: 6px;
    height: 6px;
    border-radius: 3px;
    background-color: var(--indigo200);
}

.active {
    background-color: var(--indigo700);
    width: 24px;
}

.mobile {
    margin-bottom: 8px;
}

.desktop {
    flex: 1;
}
